import React, { Component } from 'react';
import { Button, Image, message, Space } from 'antd';
import 'antd/dist/antd.css';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import ClipboardJS from 'clipboard';
import ReactPlayer from 'react-player';

import SovaLineup from './SovaLineup.js';
import {
    createTrajectory,
    checkBooleanInfo
} from '../utils/TrajectoryUtils.js';

let copy;

class Lineup extends Component {

    componentDidMount() {
        copy = new ClipboardJS('.copyBtn');
        copy.on('success', function (e) {
            message.success('Successfully copied trajectory data to clipboard');
        })

        copy.on('error', function (e) {
            message.error('Could not copy trajectory data to clipboard')
        })
    }

    componentWillUnmount() {
        copy.destroy();
    }

    render() {
        
        let lineUp = [];
        let customTrajectory = {};

        let trajectory;

        if (this.props.map && this.props.agent && this.props.useCase && this.props.endPointID && this.props.trajectoryID && this.props.endPointMap) {
            trajectory = this.props.endPointMap[this.props.endPointID].trajectoryList[this.props.trajectoryID];

            customTrajectory = createTrajectory(
                this.props.map.name,
                this.props.agent,
                this.props.useCase,
                this.props.endPointID,
                this.props.endPointMap[this.props.endPointID].coord,
                this.props.trajectoryID,
                trajectory);
        }
        
        if (trajectory) {
            if (this.props.agent === "Sova") {
                for (let i = 0; i < trajectory.power.length; i++) {
                    lineUp.push(<SovaLineup
                        power={trajectory.power[i]}
                        bounce={trajectory.bounce[i]}
                        jump={trajectory.jump[i]}
                        crouch={trajectory.crouch ? trajectory.crouch[i] : null}
                    />)
                }
            } else {
                lineUp.push(
                    <div className={this.props.agent + 'lineup_div'}>
                        <h3>Jump: {trajectory.jump ? "Yes" : "No"} {checkBooleanInfo(trajectory.crouch) ? (trajectory.crouch ? "Crouch: Yes" : "Crouch: No") : ""}</h3>
                    </div>
                )
            }
        }
        
        return (
            <div className='lineup_div'>
                <Space direction='vertical'>
                    <Space direction='horizontal'>
                        <Button icon={<DownloadOutlined />} onClick={() => this.props.exportJSON(customTrajectory)}>Download</Button>
                        <Button className='copyBtn' data-clipboard-text={JSON.stringify(customTrajectory)} icon={<CopyOutlined />}>Copy</Button>
                    </Space>
                    { lineUp }
                    {trajectory != null ? trajectory.images.map(image => (
                        image.video ? 
                        <Space direction='vertical'>                           
                            <ReactPlayer url={image.src} width='405px' height='228px' controls={true}/>
                            <p>{image.text}</p>
                        </Space>
                        :
                        <Space direction='vertical'>
                            <Image
                                key={image.src}
                                width='405px'
                                src={image.src}
                            />
                            <p>{image.text}</p>
                        </Space>
                    )) : null}
                </Space>
            </div>
        );
    }

}

export default Lineup;