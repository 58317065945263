import React from 'react';
import { Modal } from 'antd';
import 'antd/dist/antd.css';

import Lineup from './Lineup.js'

class DeleteTrajectoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.onOk = this.onOk.bind(this);
    }

    onOk = () => {
        this.props.deleteCustomTrajectory();
        this.props.onSuccessClose();
    }

    render() {
        let lineup;
        if (this.props.map) {
            if (this.props.agent) {
                if (this.props.useCase) {
                    if (this.props.trajectoryInfo) {
                        lineup = <Lineup agent={this.props.agent} endPointID={this.props.trajectoryInfo.endPointID} trajectoryID={this.props.trajectoryInfo.trajectoryID} map={this.props.map} useCase={this.props.useCase} endPointMap={this.props.customTrajectories[this.props.map.name][this.props.agent][this.props.useCase]} exportIndividual={this.props.exportIndividual} />
                    }
                }
            }
        }

        return(
            <Modal title="Are you sure you want to delete the following trajectory?" visible={this.props.deleteModal} onCancel={this.props.onModalClose} onOk={this.onOk} destroyOnClose={true}>
                {lineup}
            </Modal>
        )
    }

    
}

// let DeleteTrajectoryModal = (props) => {

//     const onOk = () => {
//         props.deleteCustomTrajectory();
//         props.onSuccessClose();
//     }

    

//     return (
//         <Modal title="Are you sure you want to delete the following trajectory?" visible={props.deleteModal} onCancel={props.onModalClose} onOk={onOk}>
//             {lineup}
//         </Modal>
//     );
// };

export default DeleteTrajectoryModal;