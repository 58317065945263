import React from 'react';
import { Menu } from 'antd';
import 'antd/dist/antd.css';

// const dummyRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//         onSuccess("ok");
//     }, 0);
// };

class AddButtonGroup extends React.Component {

    upload() {
        document.getElementById('upload_file').click();
    }

    render() {

        let menu;
        if (this.props.mapSelected && this.props.agentSelected && this.props.useCaseSelected) {
            switch (this.props.buttonStatus) {
                case "add":
                    menu = <Menu
                                mode='inline'
                                style={{ borderRight: 0, }}
                                selectedKeys=''
                            >
                                    <Menu.Item key={"confirm_btn"} onClick={() => { this.props.onAddConfirm() }} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                        <p style={{ color: 'white' }}>Confirm</p>
                                    </Menu.Item>,
                                    <Menu.Item key={"cancel_btn"} onClick={() => { this.props.onCancel() }} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                        <p style={{ color: 'white' }}>Cancel</p>
                                    </Menu.Item>
                            </Menu>;
                    break;
                case "remove":
                    menu = <Menu
                                mode='inline'
                                style={{ borderRight: 0, }}
                                selectedKeys=''
                            >
                                <Menu.Item key={"cancel_btn"} onClick={() => { this.props.onCancel() }} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                    <p style={{ color: 'white' }}>Cancel</p>
                                </Menu.Item>
                            </Menu>;
                    break;
                default:
                    menu = <Menu
                                mode='inline'
                                style={{ borderRight: 0, }}
                                selectedKeys=''
                            >
                                    <Menu.Item key={"add_btn"} onClick={() => { this.props.onAdd() }} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                        <p style={{ color: 'white' }}>Add</p>
                                    </Menu.Item>,
                                    <Menu.Item key={"remove_btn"} onClick={() => { this.props.onRemove() }} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                        <p style={{ color: 'white' }}>Remove</p>
                                    </Menu.Item>
                                    <Menu.Item key={"upload_btn"} onClick={() => this.props.openImport()} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                        <p style={{ color: 'white' }}>Import</p>
                                        {/* <Upload
                                            accept='.json'
                                            showUploadList={false}
                                            onChange={this.props.uploadTrajectoryJSON}
                                            customRequest={dummyRequest}
                                        >
                                            <Button id='upload_file' hidden/>
                                        </Upload> */}
                                    </Menu.Item>
                                        {
                                            this.props.customExist ?
                                                <Menu.Item key={"export_btn"} onClick={() => this.props.openExport(this.props.customTrajectories)} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                                                    <p style={{ color: 'white' }}>Export</p>
                                                </Menu.Item>
                                                : null
                                        }
                            </Menu>;
            }
        } else {
            menu = <Menu
                mode='inline'
                style={{ borderRight: 0, }}
                selectedKeys=''
            >
                <Menu.Item key={"upload_btn"} onClick={() => this.props.openImport()} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                    <p style={{ color: 'white' }}>Import</p>
                    {/* <Upload
                        accept='.json'
                        showUploadList={false}
                        onChange={this.props.uploadTrajectoryJSON}
                        customRequest={dummyRequest}
                    >
                        <Button id='upload_file' hidden />
                    </Upload> */}
                </Menu.Item>
                {
                    this.props.customExist ?
                    <Menu.Item key={"export_btn"} onClick={() => this.props.openExport(this.props.customTrajectories)} style={{ backgroundColor: 'rgba(255,70,85,255)', margin: 0 }}>
                        <p style={{ color: 'white' }}>Export</p>
                    </Menu.Item>
                    : null
                }
            </Menu>;
        }

        return(menu)
    }

}

export default AddButtonGroup;