import React, {Component} from 'react';

class ValorantMap extends Component {

    render() {
        return (
            <div className='map_div'/>
        );
    }

}

export default ValorantMap;