import * as d3 from 'd3';

let line = d3.line();

const trapwirePointRadius = 4;
const endPointRadius = 6;
const endPointColor = 'orange';

const triangleSize = 75;
const trajectoryWidth = 3;
const trajectoryColor = 'CornflowerBlue';
const trajectoryOpacity = 0.4;

const highlightColor = 'CornflowerBlue';

// STRETCH
// let strokeColor = 'DeepSkyBlue';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DISPLAYING TRAJECTORIES                                                                                                    //
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// called by App.js. removes the previous map svg and all its children and creates a new one and draws the new map
export function drawMap(map) {

    if (map != null) {
        d3.selectAll('.map_div > *').remove();

        let svg = d3.select('.map_div').append('svg')
            .attr('id', 'map_svg')
            .attr('width', map.map_width)
            .attr('height', map.map_height);

        svg.append('svg:image')
            .attr('id', 'map_img')
            .attr('xlink:href', map.src)
            .attr('width', map.map_width)
            .attr('height', map.map_height)
    }
}

// called by App.js. removes the map svg and all its children
export function removeMap() {
    d3.selectAll('.map_div > *').remove();
}

// called by App.js. calls the corresponding ability drawing function
export function drawTrajectories(endPointMap, openLineup, setStatus, useCase) {
    setStatus('endPoint');

    d3.selectAll('#endPoints').remove();
    d3.selectAll('#trajectory').remove();

    d3.select('#map_img')
        .on("click", (event) => {
            // resets map to endpoints for when the user has clicked an endpoint
            drawTrajectories(endPointMap, openLineup, setStatus, useCase);
        });

    if (useCase === "Trapwire") {
        drawTrapwires(endPointMap, openLineup, setStatus);
    } else {
        drawAbilities(endPointMap, openLineup, setStatus);
    }
}

// draws abilities with default visualizations
function drawAbilities(endPointMap, openLineup, setStatus) {

    if (endPointMap != null) {

        let gEndPoints = d3.select('#map_svg')
            .append('g')
            .attr('id', 'endPoints')

        Object.keys(endPointMap).forEach(endPoint => {
            let endPointGroup = gEndPoints.append('g')
                .attr('id', endPoint + 'parent');

            // path and starting point should be hidden until hovered or clicked
            let pathGroup = endPointGroup.append('g')
                .attr('id', endPoint + 'paths')
                .attr('visibility', 'hidden');

            // check whether the endpoint has multiple trajectories
            if (Object.keys(endPointMap[endPoint].trajectoryList).length > 1) {

                // add all the paths and startpoints
                Object.keys(endPointMap[endPoint].trajectoryList).forEach(trajectoryID => {
                    let trajectory = endPointMap[endPoint].trajectoryList[trajectoryID]
                    let path = trajectory.path;

                    // useless check since if the endpoint is a standalone then there won't be other trajectories in the trajectoryList
                    if (path.length > 1) {
                        ////////////////////////////////////////////////////////////////
                        // add path (line)
                        let trajectoryGroup = pathGroup.append('g')
                            .attr('id', trajectoryID)
                            .attr('opacity', trajectoryOpacity)
                            .on('mouseover', function () {
                                d3.select(this)
                                    .attr('opacity', 1);
                            })
                            .on('mouseleave', function () {
                                d3.select(this)
                                    .attr('opacity', trajectoryOpacity);
                            })
                            .on('click', function () {

                                Object.keys(endPointMap[endPoint].trajectoryList).forEach(otherTrajectoryID => {
                                    if (otherTrajectoryID !== trajectoryID) {
                                        d3.select('#' + otherTrajectoryID).remove();
                                    }
                                })

                                d3.select(this)
                                    .on('mouseover', null)
                                    .on('mouseleave', null);

                                openLineup(trajectoryID, endPoint);
                            });

                        trajectoryGroup.append('path')
                            .attr('id', trajectoryID + 'path')
                            .attr('d', line(path))
                            .attr('stroke', trajectoryColor)
                            .attr('stroke-width', trajectoryWidth)
                            .attr('fill', 'none');

                        ////////////////////////////////////////////////////////////////
                        // add starting point (triangle)
                        let triangle = d3.symbol()
                            .type(d3.symbolTriangle)
                            .size(triangleSize);

                        //triangle rotation and translation
                        let start = path[0]
                        let next = path[1]
                        let degrees = calculateDegrees(start, next);

                        let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

                        trajectoryGroup.append('path')
                            .attr('id', trajectoryID + 'start')
                            .attr('d', triangle)
                            .attr('stroke', trajectoryColor)
                            .attr('fill', trajectoryColor)
                            .attr('transform', rotateTranslate)
                    }
                })

                ///////////////////////////////////////////////////////////////////////////////////////////////////
                // add singular end point (circle)

                endPointGroup.append('circle')
                    .attr('id', endPoint)
                    .attr('cx', endPointMap[endPoint].coord[0])
                    .attr('cy', endPointMap[endPoint].coord[1])
                    .attr('r', endPointRadius)
                    .attr('fill', endPointColor)
                    .on('mouseover', function () {

                        // make path and starting point visible
                        d3.select('#' + endPoint + 'paths')
                            .attr('visibility', 'visible')

                        // highlight end point and add mouseleave
                        d3.select('#' + endPoint)
                            .attr('fill', highlightColor)
                            .on('mouseleave', function () {
                                d3.select('#' + endPoint + 'paths')
                                    .attr('visibility', 'hidden');

                                d3.select('#' + endPoint)
                                    .attr('fill', endPointColor);

                            });
                    })
                    .on('click', function () {

                        // make selected endpoint highlightColor
                        d3.select('#' + endPoint)
                            .attr('fill', highlightColor)


                        // remove all other points
                        Object.keys(endPointMap).forEach(otherPoint => {
                            if (otherPoint !== endPoint) {
                                d3.select('#' + otherPoint + 'parent').remove();
                            }
                        })

                        // make path visible
                        d3.select('#' + endPoint + 'paths')
                            .attr('visibility', 'visible')

                        // remove mouseover and mouseleave events
                        d3.select(this)
                            .on('mouseover', null)
                            .on('mouseleave', null);

                        setStatus('trajectory');
                    });

            // if endpoint only has one trajectory
            } else {
                let trajectoryID = Object.keys(endPointMap[endPoint].trajectoryList)[0];
                let trajectory = endPointMap[endPoint].trajectoryList[trajectoryID]
                let path = trajectory.path;

                // check whether endpoint is standalone. if it is not, add path and start
                if (path.length > 1) {
                    ////////////////////////////////////////////////////////////////
                    // add path (line)
                    let trajectoryGroup = pathGroup.append('g')
                        .attr('id', trajectoryID)
                        .attr('opacity', trajectoryOpacity);

                    trajectoryGroup.append('path')
                        .attr('id', trajectoryID + 'path')
                        .attr('d', line(path))
                        .attr('stroke', trajectoryColor)
                        .attr('stroke-width', trajectoryWidth)
                        .attr('fill', 'none');

                    ////////////////////////////////////////////////////////////////
                    // add starting point (triangle)
                    let triangle = d3.symbol()
                        .type(d3.symbolTriangle)
                        .size(triangleSize);

                    //triangle rotation and translation
                    let start = path[0]
                    let next = path[1]
                    let degrees = calculateDegrees(start, next);

                    let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

                    trajectoryGroup.append('path')
                        .attr('id', trajectoryID + 'start')
                        .attr('d', triangle)
                        .attr('stroke', trajectoryColor)
                        .attr('fill', trajectoryColor)
                        .attr('transform', rotateTranslate)
                }

                // add endpoint
                endPointGroup.append('circle')
                    .attr('id', endPoint)
                    .attr('cx', endPointMap[endPoint].coord[0])
                    .attr('cy', endPointMap[endPoint].coord[1])
                    .attr('r', endPointRadius)
                    .attr('fill', endPointColor)
                    .on('mouseover', function () {

                        // make path and starting point visible
                        d3.select('#' + endPoint + 'paths')
                            .attr('visibility', 'visible')

                        // highlight end point and add mouseleave
                        d3.select('#' + endPoint)
                            .attr('fill', highlightColor)
                            .on('mouseleave', function () {
                                d3.select('#' + endPoint + 'paths')
                                    .attr('visibility', 'hidden');

                                d3.select('#' + endPoint)
                                    .attr('fill', endPointColor);

                            });
                    })
                    .on('click', function () {

                        // make selected endpoint highlightColor
                        d3.select('#' + endPoint)
                            .attr('fill', highlightColor)


                        // remove all other points
                        Object.keys(endPointMap).forEach(otherPoint => {
                            if (otherPoint !== endPoint) {
                                d3.select('#' + otherPoint + 'parent').remove();
                            }
                        })

                        // if endpoint is standalone, open lineup
                        if (path.length === 1) {
                            d3.select(this)
                                .on('mouseover', null)
                                .on('mouseleave', null);

                            openLineup(trajectoryID, endPoint)

                        // if endpoint is not standalone, visualize the path and open lineup
                        } else {
                            // make path visible
                            d3.select('#' + endPoint + 'paths')
                                .attr('visibility', 'visible')

                            d3.select('#' + trajectoryID)
                                .attr('opacity', 1)

                            // remove mouseover and mouseleave events
                            d3.select(this)
                                .on('mouseover', null)
                                .on('mouseleave', null);

                            openLineup(trajectoryID, endPoint)
                        }
                    });
            } 
        });
    }
}

function drawTriangleWall(endPointMap, openLineup, setStatus) {
    if (endPointMap != null) {
        let gEndPoints = d3.select('#map_svg')
            .append('g')
            .attr('id', 'endPoints')
        
        // goes through each trajectory and visualize each of them
        Object.keys(endPointMap).forEach(endPoint => {
            Object.keys(endPointMap[endPoint].trajectoryList).forEach(trajectoryID => {
                let trajectory = endPointMap[endPoint].trajectoryList[trajectoryID]
                let path = trajectory.path;
                let start = path[0];

                // individual trapwire group
                let triangleWall = gEndPoints.append('g')
                    .attr('id', trajectoryID)
                    .attr('opacity', trajectoryOpacity)
                    .on('mouseover', function () {
                        d3.select(this)
                            .attr('opacity', 1);
                    })
                    .on('mouseleave', function () {
                        d3.select(this)
                            .attr('opacity', trajectoryOpacity);
                    })
                    .on('click', function () {

                        Object.keys(endPointMap[endPoint].trajectoryList).forEach(otherTrajectoryID => {
                            if (otherTrajectoryID !== trajectoryID) {
                                d3.select('#' + otherTrajectoryID).remove();
                            }
                        })

                        d3.select(this)
                            .on('mouseover', null)
                            .on('mouseleave', null);

                        openLineup(trajectoryID, endPoint);
                    });;

                // add path and starting triangle if they exist
                if (path.length > 1) {
                    let end = path[path.length - 1];
                    triangleWall.append('path')
                        .attr('id', trajectoryID + 'path')
                        .attr('d', line(path))
                        .attr('stroke', trajectoryColor)
                        .attr('stroke-width', trajectoryWidth)
                        .attr('fill', 'none');

                    ////////////////////////////////////////////////////////////////
                    // add starting point (triangle)
                    let triangle = d3.symbol()
                        .type(d3.symbolTriangle)
                        .size(triangleSize);

                    //triangle rotation and translation
                    let next = path[1]
                    let degrees = calculateDegrees(start, next);

                    let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

                    triangleWall.append('path')
                        .attr('id', trajectoryID + 'start')
                        .attr('d', triangle)
                        .attr('stroke', trajectoryColor)
                        .attr('fill', trajectoryColor)
                        .attr('transform', rotateTranslate)

                } else {
                    // add circle
                    triangleWall.append('circle')
                        .attr('id', trajectoryID + 'start')
                        .attr('cx', start[0])
                        .attr('cy', start[1])
                        .attr('r', trapwirePointRadius)
                        .attr('fill', trajectoryColor);
                }
            })
        })
    }
}

// draws Cypher trapwires
function drawTrapwires(endPointMap, openLineup, setStatus) {
    
    if (endPointMap != null) {    

        let gEndPoints = d3.select('#map_svg')
            .append('g')
            .attr('id', 'endPoints')

        // goes through each trajectory and visualize each of them
        Object.keys(endPointMap).forEach(endPoint => {
            Object.keys(endPointMap[endPoint].trajectoryList).forEach(trajectoryID => {
                let trajectory = endPointMap[endPoint].trajectoryList[trajectoryID]
                let path = trajectory.path;
                let start = path[0];

                // individual trapwire group
                let trapwire = gEndPoints.append('g')
                    .attr('id', trajectoryID)
                    .attr('opacity', trajectoryOpacity)
                    .on('mouseover', function () {
                        d3.select(this)
                            .attr('opacity', 1);
                    })
                    .on('mouseleave', function () {
                        d3.select(this)
                            .attr('opacity', trajectoryOpacity);
                    })
                    .on('click', function () {

                        Object.keys(endPointMap[endPoint].trajectoryList).forEach(otherTrajectoryID => {
                            if (otherTrajectoryID !== trajectoryID) {
                                d3.select('#' + otherTrajectoryID).remove();
                            }
                        })

                        d3.select(this)
                            .on('mouseover', null)
                            .on('mouseleave', null);

                        openLineup(trajectoryID, endPoint);
                    });;

                // add circle
                trapwire.append('circle')
                    .attr('id', trajectoryID + 'start')
                    .attr('cx', start[0])
                    .attr('cy', start[1])
                    .attr('r', trapwirePointRadius)
                    .attr('fill', trajectoryColor);

                // add path and other circle if they exist
                if (path.length > 1) {
                    let end = path[path.length - 1];
                    trapwire.append('path')
                        .attr('id', trajectoryID + 'path')
                        .attr('d', line(path))
                        .attr('stroke', trajectoryColor)
                        .attr('stroke-width', trajectoryWidth)
                        .attr('fill', 'none');

                    trapwire.append('circle')
                        .attr('id', trajectoryID + 'end')
                        .attr('cx', end[0])
                        .attr('cy', end[1])
                        .attr('r', trapwirePointRadius)
                        .attr('fill', trajectoryColor);
                }
            })
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ADDING TRAJECTORIES                                                                                                        //
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Add new trajectory. NOTE: NEWTRAJECTORY IS IN REVERSE ORDER! GOES FROM ENDPOINT -> START
// called by App.js. handles calling corresponding add ability drawing function
export function addTrajectory(map, endPointMap, newTrajectory, setNewTrajectory, setStatus, notAvailableAlert, setStandalone, useCase) {
    if (useCase === 'Trapwire') {
        addTrapwires(map, newTrajectory, setNewTrajectory, setStatus, setStandalone);
    } else {
        addAbility(map, endPointMap, newTrajectory, setNewTrajectory, setStatus, notAvailableAlert, setStandalone);
    }
}

// draws add ability for Cypher trapwires
function addTrapwires(map, newTrajectory, setNewTrajectory, setStatus, setStandalone) {
    setStandalone(false);

    if (map != null) {

        d3.selectAll('#endPoints').remove();

        let endPointsGroup = d3.select('#map_svg')
            .append('g')
            .attr('id', 'endPoints');

        // add onClick to map_img so that when the map is clicked, if the new path has length less than 2, the clicked location is added to the path
        d3.select('#map_img')
            .on("click", (event) => {

                // change status to guide user to create path from new end point
                if (newTrajectory.length === 0) {
                    setStatus('addPath');
                }

                // if the new path has not reached max length, append clicked location to the path
                if (newTrajectory.length < 2) {
                    let x = Math.round(d3.pointer(event)[0]);
                    let y = Math.round(d3.pointer(event)[1]);
                    newTrajectory.push([x, y]);
                    setNewTrajectory(newTrajectory);
                    drawNewTrapwire(newTrajectory);

                    // if the updated path is now max length, change status to guide use to either confirm or delete the path
                    if (newTrajectory.length === 2) {
                        setStatus('maxLengthInfo');
                    }
                }
            }).on('mousemove', function (event) {

                // draws a circle at position of the user's mouse
                if (newTrajectory.length < 2) {
                    d3.select('#tempEndpointGroup').remove();

                    let tempEndpointGroup = endPointsGroup.append('g')
                        .attr('id', 'tempEndpointGroup');

                    let x = Math.round(d3.pointer(event)[0]);
                    let y = Math.round(d3.pointer(event)[1]);
                    tempEndpointGroup.append('circle')
                        .attr('cx', x)
                        .attr('cy', y)
                        .attr('r', trapwirePointRadius)
                        .attr('fill', highlightColor)
                        .attr('opacity', trajectoryOpacity)
                        .on('mousemove', function (event) {
                            let x = Math.round(d3.pointer(event)[0]);
                            let y = Math.round(d3.pointer(event)[1]);
                            d3.select(this)
                                .attr('cx', x)
                                .attr('cy', y);
                        })
                        .on('click', function (event) {
                            let x = Math.round(d3.pointer(event)[0]);
                            let y = Math.round(d3.pointer(event)[1]);
                            newTrajectory.push([x, y]);
                            setNewTrajectory(newTrajectory);
                            drawNewTrapwire(newTrajectory);

                        })
                }
            });
    }
}

// called when newTrajectory is updated
function drawNewTrapwire(newTrajectory) {

    // remove drawn endPoints group and previously drawn trajectory group
    d3.selectAll('#trajectory').remove();

    // append new trajectory group
    let trapwire = d3.select('#map_svg')
        .append('g')
        .attr('id', 'trajectory');

    let start = newTrajectory[0]
    trapwire.append('circle')
        .attr('id', 'trajectorystart')
        .attr('cx', start[0])
        .attr('cy', start[1])
        .attr('r', trapwirePointRadius)
        .attr('fill', trajectoryColor);

    if (newTrajectory.length > 1) {
        let end = newTrajectory[newTrajectory.length - 1];
        trapwire.append('path')
            .attr('id', 'trajectorypath')
            .attr('d', line(newTrajectory))
            .attr('stroke', trajectoryColor)
            .attr('stroke-width', trajectoryWidth)
            .attr('fill', 'none');

        trapwire.append('circle')
            .attr('id', 'trajectoryend')
            .attr('cx', end[0])
            .attr('cy', end[1])
            .attr('r', trapwirePointRadius)
            .attr('fill', trajectoryColor);
    }
}

// draws add ability with default visualizations
function addAbility(map, endPointMap, newTrajectory, setNewTrajectory, setStatus, notAvailableAlert, setStandalone) {
    setStandalone(false);

    if (map != null) {

        // remove all previous endpoints (from useCase map)
        d3.selectAll('#endPoints').remove();

        // add onClick to map_img so that when the map is clicked, if the new path has length less than 4, the clicked location is added to the path
        d3.select('#map_img')
            .on("click", (event) => {
                // console.log(d3.pointer(event));
                // console.log("map clicked! adding new point...")

                // change status to guide user to create path from new end point
                if (newTrajectory.length === 0) {
                    setStatus('addPath');
                }

                // if the new path has not reached max length, append clicked location to the path
                if (newTrajectory.length < 4) {
                    let x = Math.round(d3.pointer(event)[0]);
                    let y = Math.round(d3.pointer(event)[1]);
                    newTrajectory.push([x, y]);
                    setNewTrajectory(newTrajectory);
                    drawNewTrajectory(newTrajectory);

                    // if the updated path is now max length, change status to guide use to either confirm or delete the path
                    if (newTrajectory.length === 4) {
                        setStatus('maxLengthInfo');
                    }
                }

            });

        // draw endpoints that already exist
        drawEndpoints(endPointMap, newTrajectory, setNewTrajectory, setStatus, notAvailableAlert, setStandalone);
    }
}

// only called before anything is added to newTrajectory
function drawEndpoints(endPointMap, newTrajectory, setNewTrajectory, setStatus, notAvailableAlert, setStandalone) {

    setStatus('addEndpoint')

    // remove previously drawn endPoints ()
    d3.selectAll('#endPoints').remove();

    let endPointsGroup = d3.select('#map_svg')
        .append('g')
        .attr('id', 'endPoints');
    
    if (endPointMap != null) {

        Object.keys(endPointMap).forEach(endPoint => {
            endPointsGroup.append('circle')
                .attr('class', 'endPoint')
                .attr('cx', endPointMap[endPoint].coord[0])
                .attr('cy', endPointMap[endPoint].coord[1])
                .attr('r', endPointRadius)
                .attr('fill', endPointColor)
                .on('click', function () {
                    
                    let firstKey = Object.keys(endPointMap[endPoint].trajectoryList)[0];
                    let firstPathObj = endPointMap[endPoint].trajectoryList[firstKey];
                    if (Object.keys(endPointMap[endPoint].trajectoryList).length === 1 && firstPathObj.path.length === 1) {
                        notAvailableAlert();
                    } else {
                        setStandalone(true);
                        newTrajectory.push(endPointMap[endPoint].coord);
                        setNewTrajectory(newTrajectory);
                        drawNewTrajectory(newTrajectory);
                    }
                })
                .on('mouseover', function () {
                    
                    d3.selectAll('#tempEndpointGroup').remove();

                    d3.select(this)
                        .attr('fill', highlightColor)
                        .on('mouseleave', function () {
                            d3.select(this)
                                .attr('fill', endPointColor)
                                .on('mouseleave', null);
                        })
                });
        })
    }

    // add hovering point at mouse. mousemove on both the map img and the temp endpoint because mousemove for temp endpoint enhances tracking and mousemove for map img handles when mouse moves too fast
    d3.select('#map_img')
        .on('mousemove', function (event) {
            d3.select('#tempEndpointGroup').remove();

            let tempEndpointGroup = endPointsGroup.append('g')
                .attr('id', 'tempEndpointGroup');

            let x = Math.round(d3.pointer(event)[0]);
            let y = Math.round(d3.pointer(event)[1]);
            tempEndpointGroup.append('circle')
                .attr('cx', x)
                .attr('cy', y)
                .attr('r', endPointRadius)
                .attr('fill', highlightColor)
                .attr('opacity', trajectoryOpacity)
                .on('mousemove', function (event) {
                    let x = Math.round(d3.pointer(event)[0]);
                    let y = Math.round(d3.pointer(event)[1]);
                    d3.select(this)
                        .attr('cx', x)
                        .attr('cy', y);
                    
                    d3.selectAll('.endPoint').raise();
                })
                .on('click', function (event) {
                    let x = Math.round(d3.pointer(event)[0]);
                    let y = Math.round(d3.pointer(event)[1]);
                    newTrajectory.push([x, y]);
                    setNewTrajectory(newTrajectory);
                    drawNewTrajectory(newTrajectory);

                })
        });
}

// called when newTrajectory gets updated
function drawNewTrajectory(newTrajectory) {

    // remove drawn endPoints group and previously drawn trajectory group
    d3.selectAll('#endPoints').remove();
    d3.selectAll('#trajectory').remove();
    
    // append new trajectory group
    let trajectoryGroup = d3.select('#map_svg')
        .append('g')
        .attr('id', 'trajectory');

    // if newTrajectory does not consist of a single point, draw the path that connects the points and the starting triangle
    if (newTrajectory.length !== 1) {
        trajectoryGroup.append('path')
            .attr('d', line(newTrajectory))
            .attr('stroke', trajectoryColor)
            .attr('stroke-width', trajectoryWidth)
            .attr('fill', 'none');

        
        // draw starting triangle
        let triangle = d3.symbol()
            .type(d3.symbolTriangle)
            .size(triangleSize);

        let start = newTrajectory[newTrajectory.length - 1]
        let next = newTrajectory[newTrajectory.length - 2]
        let degrees = calculateDegrees(start, next);

        let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

        trajectoryGroup.append('path')
            .attr('d', triangle)
            .attr('stroke', trajectoryColor)
            .attr('fill', trajectoryColor)
            .attr('transform', rotateTranslate);
    }

    // draw the endPoint
    let end = newTrajectory[0];
    trajectoryGroup.append('circle')
        .attr('cx', end[0])
        .attr('cy', end[1])
        .attr('r', endPointRadius)
        .attr('fill', highlightColor);

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// REMOVING TRAJECTORIES                                                                                                      //
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// draw all current trajectory (hoverable). on click, remove all others and show clicked trajectory highlighted
// called by App.js. handles calling corresponding delete ability function
export function deleteTrajectory(customEndPointMap, triggerConfirmDelete, setStatus, useCase) {
    setStatus('remove');
    if (useCase === 'Trapwire') {
        deleteTrapwire(customEndPointMap, triggerConfirmDelete, setStatus);
    } else {
        deleteAbility(customEndPointMap, triggerConfirmDelete, setStatus, useCase);
    }
}

// draws delete ability for Cypher trapwires
function deleteTrapwire(customEndPointMap, triggerConfirmDelete, setStatus) {    

    // remove drawn endPoints group and previously drawn trajectory group
    d3.selectAll('#endPoints').remove();
    d3.selectAll('#trajectory').remove();

    // lets user reset the deletion map
    d3.select("#map_img")
        .on("click", () => {
            deleteTrapwire(customEndPointMap, triggerConfirmDelete, setStatus);
        });

    let gEndPoints = d3.select('#map_svg')
        .append('g')
        .attr('id', 'endPoints')

    // visualize every trapwire
    Object.keys(customEndPointMap).forEach(endPoint => {
        Object.keys(customEndPointMap[endPoint].trajectoryList).forEach(trajectoryID => {
            let trajectory = customEndPointMap[endPoint].trajectoryList[trajectoryID]
            let path = trajectory.path;

            let start = path[0];

            let trapwire = gEndPoints.append('g')
                .attr('id', trajectoryID)
                .attr('opacity', trajectoryOpacity)
                .on('mouseover', function () {
                    d3.select(this)
                        .attr('opacity', 1);
                })
                .on('mouseleave', function () {
                    d3.select(this)
                        .attr('opacity', trajectoryOpacity);
                })
                .on('click', function () {

                    Object.keys(customEndPointMap[endPoint].trajectoryList).forEach(otherTrajectoryID => {
                        if (otherTrajectoryID !== trajectoryID) {
                            d3.select('#' + otherTrajectoryID).remove();
                        }
                    })

                    d3.select(this)
                        .on('mouseover', null)
                        .on('mouseleave', null);

                    triggerConfirmDelete({
                        "endPointID": endPoint,
                        "trajectoryID": trajectoryID
                    });
                });;

            trapwire.append('circle')
                .attr('id', trajectoryID + 'start')
                .attr('cx', start[0])
                .attr('cy', start[1])
                .attr('r', trapwirePointRadius)
                .attr('fill', trajectoryColor);

            if (path.length > 1) {
                let end = path[path.length - 1];
                trapwire.append('path')
                    .attr('id', trajectoryID + 'path')
                    .attr('d', line(path))
                    .attr('stroke', trajectoryColor)
                    .attr('stroke-width', trajectoryWidth)
                    .attr('fill', 'none');

                trapwire.append('circle')
                    .attr('id', trajectoryID + 'end')
                    .attr('cx', end[0])
                    .attr('cy', end[1])
                    .attr('r', trapwirePointRadius)
                    .attr('fill', trajectoryColor);
            }
        })
    })


}

// draws delete ability for abilities with default visualization
function deleteAbility(customEndPointMap, triggerConfirmDelete, setStatus, useCase) {

    // remove drawn endPoints group and previously drawn trajectory group
    d3.selectAll('#endPoints').remove();
    d3.selectAll('#trajectory').remove();

    // lets user reset the deletion map
    d3.select("#map_img")
        .on("click", () => {
            deleteTrajectory(customEndPointMap, triggerConfirmDelete, setStatus, useCase);
        });

    let gEndPoints = d3.select('#map_svg')
        .append('g')
        .attr('id', 'endPoints')

    Object.keys(customEndPointMap).forEach(endPoint => {
        let endPointGroup = gEndPoints.append('g')
            .attr('id', endPoint + 'parent');

        // path and starting point should be hidden until hovered or clicked
        let pathGroup = endPointGroup.append('g')
            .attr('id', endPoint + 'paths')
            .attr('visibility', 'hidden');

        // check whether endpoint has multiple trajectories
        if (Object.keys(customEndPointMap[endPoint].trajectoryList).length > 1) {
            
            // add all trajectory paths and starting points
            Object.keys(customEndPointMap[endPoint].trajectoryList).forEach(trajectoryID => {
                let trajectory = customEndPointMap[endPoint].trajectoryList[trajectoryID]
                let path = trajectory.path;

                // useless check since if the endpoint is a standalone then there won't be other trajectories in the trajectoryList
                if (path.length > 1) {
                    ////////////////////////////////////////////////////////////////
                    // add path (line)
                    let trajectoryGroup = pathGroup.append('g')
                        .attr('id', trajectoryID)
                        .attr('opacity', trajectoryOpacity)
                        .on('mouseover', function () {
                            d3.select(this)
                                .attr('opacity', 1);
                        })
                        .on('mouseleave', function () {
                            d3.select(this)
                                .attr('opacity', trajectoryOpacity);
                        })
                        .on('click', function () {

                            Object.keys(customEndPointMap[endPoint].trajectoryList).forEach(otherTrajectoryID => {
                                if (otherTrajectoryID !== trajectoryID) {
                                    d3.select('#' + otherTrajectoryID).remove();
                                }
                            })

                            d3.select(this)
                                .on('mouseover', null)
                                .on('mouseleave', null);

                            triggerConfirmDelete({
                                "endPointID": endPoint,
                                "trajectoryID": trajectoryID
                            });
                        });


                    trajectoryGroup.append('path')
                        .attr('id', trajectoryID + 'path')
                        .attr('d', line(path))
                        .attr('stroke', trajectoryColor)
                        .attr('stroke-width', trajectoryWidth)
                        .attr('fill', 'none');

                    ////////////////////////////////////////////////////////////////
                    // add starting point (triangle)
                    let triangle = d3.symbol()
                        .type(d3.symbolTriangle)
                        .size(triangleSize);

                    //triangle rotation and translation
                    let start = path[0]
                    let next = path[1]
                    let degrees = calculateDegrees(start, next);

                    let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

                    trajectoryGroup.append('path')
                        .attr('id', trajectoryID + 'start')
                        .attr('d', triangle)
                        .attr('stroke', trajectoryColor)
                        .attr('fill', trajectoryColor)
                        .attr('transform', rotateTranslate)
                }
            })

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            // add singular end point (circle)

            endPointGroup.append('circle')
                .attr('id', endPoint)
                .attr('cx', customEndPointMap[endPoint].coord[0])
                .attr('cy', customEndPointMap[endPoint].coord[1])
                .attr('r', endPointRadius)
                .attr('fill', endPointColor)
                .on('mouseover', function () {

                    // make path and starting point visible
                    d3.select('#' + endPoint + 'paths')
                        .attr('visibility', 'visible')

                    // highlight end point and add mouseleave
                    d3.select('#' + endPoint)
                        .attr('fill', highlightColor)
                        .on('mouseleave', function () {
                            d3.select('#' + endPoint + 'paths')
                                .attr('visibility', 'hidden');

                            d3.select('#' + endPoint)
                                .attr('fill', endPointColor);

                        });
                })
                .on('click', function () {

                    // make selected endpoint highlightColor
                    d3.select('#' + endPoint)
                        .attr('fill', highlightColor)


                    // remove all other points
                    Object.keys(customEndPointMap).forEach(otherPoint => {
                        if (otherPoint !== endPoint) {
                            d3.select('#' + otherPoint + 'parent').remove();
                        }
                    })

                    // make path visible
                    d3.select('#' + endPoint + 'paths')
                        .attr('visibility', 'visible')

                    // remove mouseover and mouseleave events
                    d3.select(this)
                        .on('mouseover', null)
                        .on('mouseleave', null);

                        //setStatus('trajectory');
                });
            
        // if endpoint only has one trajectory
        } else {
            let trajectoryID = Object.keys(customEndPointMap[endPoint].trajectoryList)[0];
            let trajectory = customEndPointMap[endPoint].trajectoryList[trajectoryID]
            let path = trajectory.path;
 
            // check whether endpoint is standalone
            if (path.length > 1) {
                ////////////////////////////////////////////////////////////////
                // add path (line)
                let trajectoryGroup = pathGroup.append('g')
                    .attr('id', trajectoryID)
                    .attr('opacity', trajectoryOpacity)

                trajectoryGroup.append('path')
                    .attr('id', trajectoryID + 'path')
                    .attr('d', line(path))
                    .attr('stroke', trajectoryColor)
                    .attr('stroke-width', trajectoryWidth)
                    .attr('fill', 'none');

                ////////////////////////////////////////////////////////////////
                // add starting point (triangle)
                let triangle = d3.symbol()
                    .type(d3.symbolTriangle)
                    .size(triangleSize);

                //triangle rotation and translation
                let start = path[0]
                let next = path[1]
                let degrees = calculateDegrees(start, next);

                let rotateTranslate = 'translate(' + start[0] + ' ' + start[1] + ') rotate(' + degrees + ')';

                trajectoryGroup.append('path')
                    .attr('id', trajectoryID + 'start')
                    .attr('d', triangle)
                    .attr('stroke', trajectoryColor)
                    .attr('fill', trajectoryColor)
                    .attr('transform', rotateTranslate)
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            // add end point (circle)

            endPointGroup.append('circle')
                .attr('id', endPoint)
                .attr('cx', customEndPointMap[endPoint].coord[0])
                .attr('cy', customEndPointMap[endPoint].coord[1])
                .attr('r', endPointRadius)
                .attr('fill', endPointColor)
                .on('mouseover', function () {

                    // make path and starting point visible
                    d3.select('#' + endPoint + 'paths')
                        .attr('visibility', 'visible')

                    // highlight end point and add mouseleave
                    d3.select('#' + endPoint)
                        .attr('fill', highlightColor)
                        .on('mouseleave', function () {
                            d3.select('#' + endPoint + 'paths')
                                .attr('visibility', 'hidden');

                            d3.select('#' + endPoint)
                                .attr('fill', endPointColor);

                        });
                })
                .on('click', function () {

                    // make selected endpoint highlightColor
                    d3.select('#' + endPoint)
                        .attr('fill', highlightColor)


                    // remove all other points
                    Object.keys(customEndPointMap).forEach(otherPoint => {
                        if (otherPoint !== endPoint) {
                            d3.select('#' + otherPoint + 'parent').remove();
                        }
                    })

                    // if endpoint is standalone, trigger confirm delete
                    if (path.length === 1) {
                        d3.select(this)
                            .on('mouseover', null)
                            .on('mouseleave', null);

                        triggerConfirmDelete({
                            "endPointID": endPoint,
                            "trajectoryID": trajectoryID
                        });

                    // if endpoint has trajectory, visualize the trajectory and trigger confirm delete
                    } else {
                        // make path visible
                        d3.select('#' + endPoint + 'paths')
                            .attr('visibility', 'visible')

                        
                        d3.select('#' + trajectoryID)
                            .attr('opacity', 1);

                        // remove mouseover and mouseleave events
                        d3.select(this)
                            .on('mouseover', null)
                            .on('mouseleave', null);

                        triggerConfirmDelete({
                            "endPointID": endPoint,
                            "trajectoryID": trajectoryID
                        });
                        //setStatus('trajectory');
                    }
                });

        }
        

    });
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UTILITY                                                                                                                    //
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// calculates the rotation of starting triangle
function calculateDegrees(start, end) {
    let radian = Math.atan((end[1] - start[1]) / (end[0] - start[0]));
    let degrees = radian * (180 / Math.PI);

    if (end[1] - start[1] > 0) {
        if (degrees > 0) {
            degrees += 90;
        } else if (degrees < 0) {
            degrees -= 90;
        }
    } else if (end[1] - start[1] < 0) {
        if (degrees > 0) {
            degrees -= 90;
        } else if (degrees < 0) {
            degrees += 90;
        }
    }

    return degrees;
}
