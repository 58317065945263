import React from 'react';
import { Drawer, Layout, message, notification, Row } from 'antd';

import ValorantMap from '../components/ValorantMap.js';
import SelectionMenu from '../components/SelectionMenu.js';
import AddButtonGroup from '../components/AddButtonGroup.js';
import AddTrajectoryModal from '../components/AddTrajectoryModal.js';
import DeleteTrajectoryModal from '../components/DeleteTrajectoryModal.js'
import Lineup from '../components/Lineup';
import ExportModal from '../components/ExportModal.js';
import ImportModal from '../components/ImportModal.js';
import AbilityMenu from '../components/AbilityMenu.js';

// import UpdateModal from './components/UpdateModal.js';

import { drawMap, removeMap, drawTrajectories, addTrajectory, deleteTrajectory } from '../components/Vis.js';

import { MessageOutlined } from '@ant-design/icons';

import bind from '../img/bind.png';
import haven from '../img/haven.png';
import split from '../img/split.png';
import ascent from '../img/ascent.png';
import icebox from '../img/icebox.png';
import breeze from '../img/breeze.png';
import fracture from '../img/fracture.png';
import pearl from '../img/pearl.png';
import lotus from '../img/lotus.png';
import sunset from '../img/sunset.png';

import '../css/styles.css';
import 'antd/dist/antd.css';

import {
    checkArrayInfo,
    checkTrajectoryImage,
    checkBooleanInfo
} from '../utils/TrajectoryUtils.js';


const trajectoryData = require('../data/trajectoryData.json');
// const VERSION = '1.1.0';

const { Content, Sider } = Layout;

class MapPage extends React.Component {

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // determines available buttons                                                                                               //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    supportedMaps = {
        "Bind": {
            name: "Bind",
            src: bind,
            map_width: 1024,
            map_height: 1024
        },
        "Haven": {
            name: "Haven",
            src: haven,
            map_width: 1024,
            map_height: 1024,
        },
        "Split": {
            name: "Split",
            src: split,
            map_width: 1024,
            map_height: 1024,
        },
        "Ascent": {
            name: "Ascent",
            src: ascent,
            map_width: 1024,
            map_height: 1024,
        },
        "Icebox": {
            name: "Icebox",
            src: icebox,
            map_width: 1024,
            map_height: 1024,
        },
        "Breeze": {
            name: "Breeze",
            src: breeze,
            map_width: 1024,
            map_height: 1024,
        },
        "Fracture": {
            name: "Fracture",
            src: fracture,
            map_width: 1024,
            map_height: 1024,
        },
        "Pearl": {
            name: "Pearl",
            src: pearl,
            map_width: 1024,
            map_height: 1024,
        },
        "Lotus": {
            name: "Lotus",
            src: lotus,
            map_width: 1024,
            map_height: 1024,
        },
        "Sunset": {
            name: "Sunset",
            src: sunset,
            map_width: 1024,
            map_height: 1024,
        },
    }

    supportedCases = {
        "Brimstone": [
            "Incendiary", "Sky Smoke", "Orbital Strike"
        ],
        "Phoenix": [
            "Hot Hands", "Blaze"
        ],
        "Sage": [
            "Slow Orb", "Barrier Orb"
        ],
        "Sova": [
            "Recon Bolt", "Shock Bolt"
        ],
        "Viper": [
            "Toxic Screen", "Poison Cloud", "Snake Bite", "Viper's Pit"
        ],
        "Cypher": [
            "Cyber Cage", "Spycam", "Trapwire"
        ],
        "Reyna": [
            "Leer",
        ],
        "Killjoy": [
            "Alarmbot", "Turret", "Nanoswarm", "Lockdown"
        ],
        "Breach": [
            "Flashpoint", "Fault Line", "Aftershock", "Rolling Thunder"
        ],
        "Omen": [
            "Paranoia", "Dark Cover", "Shrouded Step"
        ],
        "Jett": [
            "Updraft", "Tailwind", "Cloudburst"
        ],
        "Raze": [
            "Paint Shells", "Boom Bot"
        ],
        "Skye": [
            "Trailblazer", "Guiding Light"
        ],
        "Yoru": [
            "Blindside", "Gatecrash", "Fakeout"
        ],
        "Astra": [
            "Nova Pulse", "Nebula", "Gravity Well", "Cosmic Divide"
        ],
        "KAY/O": [
            "FLASH/drive", "ZERO/point", "FRAG/ment"
        ],
        "Chamber": [
            "Rendevous", "Trademark"
        ],
        "Neon": [
            "Relay Bolt", "Fast Lane"
        ],
        "Fade": [
            "Seize", "Haunt", "Prowler", "Nightfall"
        ],
        "Harbor": [
            "High Tide", "Cove", "Cascade", "Reckoning"
        ],
        "Gekko": [
            "Wingman", "Dizzy", "Mosh Pit", "Thrash"
        ],
        "Deadlock": [
            "Sonic Sensor", "Barrier Mesh", "GravNet", "Annihilation"
        ],
        "Iso": [
            "Undercut", "Contingency", "Kill Contract"
        ]
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CONSTRUCTOR                                                                                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    constructor(props) {
        super(props)

        let mapMap = {};
        Object.keys(this.supportedMaps).forEach(map => {
            if (map !== 'Bind') {
                mapMap[map] = false;
            } else {
                mapMap[map] = true;
            }
        })

        let agentMap = {};
        Object.keys(this.supportedCases).forEach(agent => {
            agentMap[agent] = false;
        })

        this.state = {
            // user selection and corresponding button maps
            map: this.supportedMaps['Bind'],
            mapMap: mapMap,
            agent: null,
            agentMap: agentMap,
            useCase: null,
            useCaseMap: null,

            // trajectory data
            trajectoryData: null,
            endPointMap: null,
            customTrajectories: null,
            // currentTrajectory: null,
            currentEndpoint: null,
            currentTrajectoryID: null,
            newTrajectory: [],
            removeTrajectoryInfo: null,
            exportObj: null,

            // modal and button group statuses
            lineup: false,
            collapsed: false,
            buttonStatus: 'default',
            addModal: false,
            deleteModal: false,
            exportModal: false,
            importModal: false,

            standalone: false,

            firstTimeModal: false,
            // updateModal: false,

            about: false,
            howto: false,

            status: 'map'
        }

        this.initializeMapMap = this.initializeMapMap.bind(this);
        this.initializeAgentMap = this.initializeAgentMap.bind(this);

        this.drawMap = this.drawMap.bind(this);
        this.drawTrajectories = this.drawTrajectories.bind(this);

        this.openLineup = this.openLineup.bind(this);
        this.closeLineup = this.closeLineup.bind(this);

        this.onModalClose = this.onModalClose.bind(this);
        this.onSuccessClose = this.onSuccessClose.bind(this);

        this.setMap = this.setMap.bind(this);
        this.setAgent = this.setAgent.bind(this);
        this.setUseCase = this.setUseCase.bind(this);
        this.setStatus = this.setStatus.bind(this);

        this.getUseCaseMap = this.getUseCaseMap.bind(this);

        this.onAdd = this.onAdd.bind(this);
        this.onAddConfirm = this.onAddConfirm.bind(this);

        this.onCancel = this.onCancel.bind(this);

        this.onRemove = this.onRemove.bind(this);
        this.triggerConfirmDelete = this.triggerConfirmDelete.bind(this);

        this.exportJSON = this.exportJSON.bind(this);

        this.uploadTrajectoryJSON = this.uploadTrajectoryJSON.bind(this);
        this.checkValidTrajectories = this.checkValidTrajectories.bind(this);
        this.addCustomTrajectory = this.addCustomTrajectory.bind(this);
        this.deleteCustomTrajectory = this.deleteCustomTrajectory.bind(this);
        this.mergeTrajectories = this.mergeTrajectories.bind(this);
        this.setCurrentTrajectories = this.setCurrentTrajectories.bind(this);

        this.setNewTrajectory = this.setNewTrajectory.bind(this);
        this.setStandalone = this.setStandalone.bind(this);

        this.clickAbout = this.clickAbout.bind(this);
        this.clickHowTo = this.clickHowTo.bind(this);

        this.openExport = this.openExport.bind(this);
        this.onExportClose = this.onExportClose.bind(this);

        this.openImport = this.openImport.bind(this);
        this.onImportClose = this.onImportClose.bind(this);

        // this.onUpdateClose = this.onUpdateClose.bind(this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CALLS INITIALIZE MAP AFTER COMPONENT MOUNTS                                                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    componentDidMount() {


        // Load custom trajectories if present
        let jointTrajectoryData = JSON.parse(JSON.stringify(trajectoryData));
        let customTrajectories;

        try {
            customTrajectories = JSON.parse(localStorage.getItem('customTrajectories'));
        } catch (e) {
            customTrajectories = {};
            message.info('Could not retrieve lineups from local storage. If this is unintended, make sure cookies are enabled for this website.', 5)
        }

        let uniqueNew = {};
        if (customTrajectories && Object.keys(customTrajectories).length !== 0) {
            uniqueNew = this.mergeTrajectories(jointTrajectoryData, customTrajectories);
            message.success("Successfully loaded lineups from local storage.", 3);
        }

        this.setState({ trajectoryData: jointTrajectoryData, customTrajectories: uniqueNew });

        this.drawMap(this.state.map, this.state.agent, this.state.useCase)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // INITIALIZE MAP AND AGENT BUTTON GROUPS                                                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    initializeMapMap() {

        let mapMap = {};

        Object.keys(this.supportedMaps).forEach(map => {
            mapMap[map] = false;
        });

        this.setState({
            mapMap: mapMap
        });
    }

    initializeAgentMap() {

        let agentMap = {};

        Object.keys(this.supportedCases).forEach(agentName => {
            agentMap[agentName] = false;
        });

        this.setState({
            agentMap: agentMap
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // LOAD AGENT ENDPOINTS AND CALLS DRAWMAP                                                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // this.drawMap
    drawMap(map, agentName, caseName) {
        if (map != null) {
            let endPointMap;
            if (agentName != null && caseName != null) {
                if (map.name in this.state.trajectoryData) {
                    if (agentName in this.state.trajectoryData[map.name]) {
                        if (caseName in this.state.trajectoryData[map.name][agentName]) {
                            endPointMap = this.state.trajectoryData[map.name][agentName][caseName];
                        }
                    }
                }
            }
            drawMap(map);
            drawTrajectories(endPointMap, this.openLineup, this.setStatus, caseName);
            this.setState({ endPointMap: endPointMap, add: false, newTrajectory: [] });
        }
    }

    // this.drawTrajectories
    drawTrajectories(map, agentName, caseName) {
        if (map != null) {
            let endPointMap;
            if (agentName != null && caseName != null) {
                if (map.name in this.state.trajectoryData) {
                    if (agentName in this.state.trajectoryData[map.name]) {
                        if (caseName in this.state.trajectoryData[map.name][agentName]) {
                            endPointMap = this.state.trajectoryData[map.name][agentName][caseName];
                        }
                    }
                }
            }
            drawTrajectories(endPointMap, this.openLineup, this.setStatus, caseName);
            this.setState({ endPointMap: endPointMap, add: false, newTrajectory: [] });
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GETTERS                                                                                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getCaseList(agentName) {
        return this.supportedCases[agentName];
    }

    getUseCaseMap(agentName) {
        let useCaseMap = {};
        this.getCaseList(agentName).forEach(useCase => {
            useCaseMap[useCase] = false;
        })
        return (useCaseMap);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // SETTERS                                                                                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setAgent(agentName) {
        if (this.state.agent == null || this.state.agent !== agentName) {

            let agentMap = this.state.agentMap;
            Object.keys(agentMap).forEach(otherAgent => {
                if (otherAgent !== agentName) {
                    agentMap[otherAgent] = false;
                } else {
                    agentMap[otherAgent] = true;
                }
            });

            this.setState({
                agent: agentName,
                agentMap: agentMap,
                useCase: null,
                useCaseMap: this.getUseCaseMap(agentName),
                status: 'useCase',

                buttonStatus: "default",
                newTrajectory: [],
            });

            this.drawTrajectories(this.state.map, agentName, null);
        }
    }

    setUseCase(caseName) {
        if (this.state.useCase == null || this.state.useCase !== caseName) {

            let useCaseMap = this.state.useCaseMap;
            Object.keys(useCaseMap).forEach(otherUseCase => {
                if (otherUseCase !== caseName) {
                    useCaseMap[otherUseCase] = false;
                } else {
                    useCaseMap[otherUseCase] = true;
                }
            });

            this.setState({
                useCase: caseName,
                useCaseMap: useCaseMap,
                status: 'endPoint',

                buttonStatus: "default",
                newTrajectory: [],
            });

            this.drawTrajectories(this.state.map, this.state.agent, caseName);
        }
    }

    setMap(mapName) {
        if (this.state.map == null || this.state.map.name !== mapName) {

            let mapMap = this.state.mapMap;
            Object.keys(mapMap).forEach(otherMap => {
                if (otherMap !== mapName) {
                    mapMap[otherMap] = false;
                } else {
                    mapMap[otherMap] = true;
                }
            })

            let status = this.state.status;
            if (this.state.agentMap == null) {
                this.initializeAgentMap();
                status = 'agent';
            }

            if (status === 'addPath' || status === 'maxLengthInfo' || status === 'addEndpoint' || status === 'remove') {
                status = 'endPoint';
            }

            let map = this.supportedMaps[mapName]

            this.setState({
                map: map,
                mapMap: mapMap,
                about: false,
                howto: false,

                status: status,

                buttonStatus: "default",
                newTrajectory: [],
            })

            this.drawMap(map, this.state.agent, this.state.useCase);
        }
    }

    setNewTrajectory(trajectory) {
        this.setState({
            newTrajectory: trajectory
        })
    }

    setStandalone(standalone) {
        this.setState({
            standalone: standalone
        })
    }

    setStatus(status) {
        this.setState({
            status: status
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // LINEUP INFO OPEN AND CLOSE                                                                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    openLineup(trajectoryID, endPointID) {
        this.setState({
            // currentTrajectory: trajectory,
            currentEndpointID: endPointID,
            currentTrajectoryID: trajectoryID,
            lineup: true
        })
    }

    closeLineup() {
        this.setState({
            // currentTrajectory: null,
            currentEndpointID: null,
            currentTrajectoryID: null,
            lineup: false
        })
        this.drawTrajectories(this.state.map, this.state.agent, this.state.useCase);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // SELECTION MENU COLLAPSE                                                                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    onCollapse = collapsed => {
        this.setState({ collapsed });
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ADD TRAJECTORY                                                                                                             //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onAdd() {
        this.setState({ buttonStatus: "add" });
        addTrajectory(this.state.map, this.state.endPointMap, this.state.newTrajectory, this.setNewTrajectory, this.setStatus, this.notAvailableAlert, this.setStandalone, this.state.useCase);
    }

    notAvailableAlert() {
        message.error("The endpoint you have selected does not have a path and therefore cannot be used for additional trajectories.");
    }

    // confirm button to confirm trajectory on map. shows modal for inputting additional information
    onAddConfirm() {
        if (this.state.newTrajectory.length >= 1) {
            if (this.state.newTrajectory.length === 1 && this.state.standalone) {
                message.info("This endpoint already has lineups and cannot be used as a standalone endpoint.");
            } else {
                this.setState({ addModal: true });
            }
        } else {
            message.info("Lineups needs at least an endpoint.")
        }
    }

    // cancel button to cancel trajectory addition on map
    onCancel() {
        this.setState({ buttonStatus: "default", newTrajectory: [], status: 'endPoint' });
        this.drawTrajectories(this.state.map, this.state.agent, this.state.useCase);
    }

    onRemove() {
        if (this.state.customTrajectories != null && this.state.customTrajectories[this.state.map.name] != null && this.state.customTrajectories[this.state.map.name][this.state.agent] != null && this.state.customTrajectories[this.state.map.name][this.state.agent][this.state.useCase] != null) {
            this.setState({ buttonStatus: "remove" });
            deleteTrajectory(this.state.customTrajectories[this.state.map.name][this.state.agent][this.state.useCase], this.triggerConfirmDelete, this.setStatus, this.state.useCase);
        } else {
            message.info("There are no custom lineups available to be removed.")
        }
    }

    triggerConfirmDelete(trajectoryInfo) {
        this.setState({ removeTrajectoryInfo: trajectoryInfo, deleteModal: true });
    }

    // hides modal and redraw trajectories
    onModalClose() {
        this.setState({ addModal: false, deleteModal: false, status: 'endPoint', buttonStatus: "default", newTrajectory: [], removeTrajectoryInfo: null });
        this.drawTrajectories(this.state.map, this.state.agent, this.state.useCase);
    }

    onSuccessClose() {
        this.setState({ addModal: false, deleteModal: false, status: 'endPoint', buttonStatus: "default", newTrajectory: [], removeTrajectoryInfo: null });
        message.success("Changes were made to custom lineups. Make sure to export them in case this website's cookies are wiped.", 5)
    }

    openExport(trajectoriesObj) {
        this.setState({ exportModal: true, exportObj: trajectoriesObj });
    }

    onExportClose() {
        this.setState({ exportModal: false, exportObj: null });
    }

    openImport() {
        this.setState({
            importModal: true
        })
    }

    onImportClose() {
        this.setState({ importModal: false });
    }

    // onUpdateClose() {
    //   this.setState({ updateModal: false });
    //   try {
    //     localStorage.setItem('version', VERSION);
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // MERGE NEW TRAJECTORIES TO TRAJECTORY LIST                                                                                  //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async exportJSON(trajectoriesObj) {
        let trajectories = JSON.stringify(trajectoriesObj);
        let blob = new Blob([trajectories], { type: 'application/json' });
        let href = await URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = href;
        link.download = "customTrajectories.json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    uploadTrajectoryJSON(info) {

        if (info.file.status === 'done') {

            let addCustomTrajectory = this.addCustomTrajectory;
            let onImportClose = this.onImportClose;
            const reader = new FileReader();
            reader.readAsText(info.file.originFileObj);
            reader.onloadend = function () {
                try {
                    let trajectories = JSON.parse(reader.result);
                    let success = addCustomTrajectory(trajectories);
                    if (success) {
                        message.success(`${info.file.name} file uploaded successfully`);
                        onImportClose();
                    } else {
                        message.error(`Could not read ${info.file.name}`);
                    }

                } catch (e) {
                    console.log(e);
                    message.error(`Could not read ${info.file.name}`);
                }
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed`);
        }
    }

    // add new trajectory to list of custom trajectories and overall trajectories
    addCustomTrajectory(newTrajectories) {
        let customTrajectories = this.state.customTrajectories;
        let trajectoryDataCopy = JSON.parse(JSON.stringify(trajectoryData));

        if (this.checkValidTrajectories(newTrajectories)) {
            if (customTrajectories == null) {
                customTrajectories = newTrajectories;
            } else {
                this.mergeTrajectories(customTrajectories, newTrajectories);
            }

            let uniqueNew = this.mergeTrajectories(trajectoryDataCopy, customTrajectories);
            this.setCurrentTrajectories(trajectoryDataCopy, uniqueNew);
            return true;
        } else {
            return false;
        }
    }

    deleteCustomTrajectory() {
        let trajectoryInfo = this.state.removeTrajectoryInfo;
        let customTrajectories = this.state.customTrajectories;
        let trajectoryDataCopy = JSON.parse(JSON.stringify(trajectoryData));

        if (Object.keys(customTrajectories[this.state.map.name][this.state.agent][this.state.useCase][trajectoryInfo.endPointID].trajectoryList).length === 1) {
            delete customTrajectories[this.state.map.name][this.state.agent][this.state.useCase][trajectoryInfo.endPointID];
            if (customTrajectories[this.state.map.name][this.state.agent][this.state.useCase] == null || Object.keys(customTrajectories[this.state.map.name][this.state.agent][this.state.useCase]).length === 0) {
                delete customTrajectories[this.state.map.name][this.state.agent][this.state.useCase];
                if (customTrajectories[this.state.map.name][this.state.agent] == null || Object.keys(customTrajectories[this.state.map.name][this.state.agent]).length === 0) {
                    delete customTrajectories[this.state.map.name][this.state.agent];
                    if (customTrajectories[this.state.map.name] == null || Object.keys(customTrajectories[this.state.map.name]).length === 0) {
                        delete customTrajectories[this.state.map.name];
                    }
                }
            }
        } else {
            delete customTrajectories[this.state.map.name][this.state.agent][this.state.useCase][trajectoryInfo.endPointID].trajectoryList[trajectoryInfo.trajectoryID];
        }

        this.mergeTrajectories(trajectoryDataCopy, customTrajectories);
        this.setCurrentTrajectories(trajectoryDataCopy, customTrajectories);
    }

    checkValidTrajectories(trajectories) {
        if (trajectories == null) {
            message.error("Lineup info was null")
            return false;
        }

        for (let i = 0; i < Object.keys(trajectories).length; i++) {
            let mapName = Object.keys(trajectories)[i];
            // check valid map
            if (!Object.keys(this.supportedMaps).includes(mapName)) {
                message.error(mapName + " is not a supported map");
                return false;
            } else if (trajectories[mapName] == null) {
                message.error("Lineup info for " + mapName + " was null");
                return false;
            }

            for (let j = 0; j < Object.keys(trajectories[mapName]).length; j++) {
                let agentName = Object.keys(trajectories[mapName])[j];
                // check valid agent
                if (!Object.keys(this.supportedCases).includes(agentName)) {
                    message.error(agentName + " is not a supported agent");
                    return false;
                } else if (trajectories[mapName][agentName] == null || Object.keys(trajectories[mapName][agentName]).length === 0) {
                    message.error("Lineup info for " + agentName + ", " + mapName + " was null or empty")
                    return false;
                }

                for (let k = 0; k < Object.keys(trajectories[mapName][agentName]).length; k++) {
                    let useCase = Object.keys(trajectories[mapName][agentName])[k];
                    // check valid useCase
                    if (!this.supportedCases[agentName].includes(useCase)) {
                        message.error(useCase + " is not a supported ability for " + agentName);
                        return false;
                    } else if (trajectories[mapName][agentName][useCase] == null || Object.keys(trajectories[mapName][agentName][useCase]).length === 0) {
                        message.error("Lineup info for " + useCase + ", " + agentName + ", " + mapName + " was null or empty")
                        return false;
                    }

                    let map_width = this.supportedMaps[mapName].map_width;
                    let map_height = this.supportedMaps[mapName].map_height;

                    for (let l = 0; l < Object.keys(trajectories[mapName][agentName][useCase]).length; l++) {
                        let endPoint = Object.keys(trajectories[mapName][agentName][useCase])[l];

                        let endPointCoord = trajectories[mapName][agentName][useCase][endPoint].coord;

                        // check valid endpoint
                        if (endPointCoord == null) {
                            message.error("Endpoint coordinates for " + endPoint + " was null");
                            return false;
                        } else if (!Array.isArray(endPointCoord) || endPointCoord.length !== 2) {
                            message.error("Endpoint coordinates for " + endPoint + " was not an array or was not an array of length 2");
                            return false;
                        } else if (isNaN(endPointCoord[0]) || isNaN(endPointCoord[1])) {
                            message.error("Endpoint coordinates for " + endPoint + " contains element(s) that are not numbers");
                            return false;
                        } else if (endPointCoord[0] < 0 || endPointCoord[0] > map_width || endPointCoord[1] < 0 || endPointCoord[1] > map_height) {
                            message.error("Endpoint " + endPoint + " is outside the bounds of " + mapName + "map");
                            return false;
                        } else if (trajectories[mapName][agentName][useCase][endPoint].trajectoryList == null || Object.keys(trajectories[mapName][agentName][useCase][endPoint].trajectoryList).length === 0) {
                            message.error("Lineup list for endpoint " + endPoint + " is null or empty");
                            return false;
                        }

                        for (let m = 0; m < Object.keys(trajectories[mapName][agentName][useCase][endPoint].trajectoryList).length; m++) {

                            let trajectoryID = Object.keys(trajectories[mapName][agentName][useCase][endPoint].trajectoryList)[m];
                            let currentTrajectory = trajectories[mapName][agentName][useCase][endPoint].trajectoryList[trajectoryID];

                            // check valid trajectory
                            if (currentTrajectory == null) {
                                message.error("Lineup " + trajectoryID + " is null");
                                return false;
                            } else if (currentTrajectory.name == null) {
                                message.error("Lineup " + trajectoryID + "'s name is null");
                                return false;
                            } else if (currentTrajectory.path == null || currentTrajectory.path.length < 1) {
                                message.error("Lineup " + trajectoryID + "'s path is null or is empty");
                                return false;
                            } else if (currentTrajectory.images == null || currentTrajectory.images.length === 0) {
                                message.error("Lineup " + trajectoryID + "'s image list is null or empty");
                                return false;
                            }

                            for (let n = 0; n < currentTrajectory.images.length; n++) {
                                let trajectoryImage = currentTrajectory.images[n];
                                if (!checkTrajectoryImage(trajectoryImage, trajectoryID)) {
                                    return false;
                                }
                            }

                            // check valid additional info (Sova)
                            if (agentName === "Sova") {
                                if (!checkArrayInfo(currentTrajectory.power, "number")) {
                                    message.error("Lineup " + trajectoryID + "'s power list is invalid");
                                    return false;
                                } else if (!checkArrayInfo(currentTrajectory.bounce, "number")) {
                                    message.error("Lineup " + trajectoryID + "'s bounce list is invalid");
                                    return false;
                                } else if (!checkArrayInfo(currentTrajectory.jump, "boolean")) {
                                    message.error("Lineup " + trajectoryID + "'s jump list is invalid");
                                    return false;
                                } else if (currentTrajectory.power.length !== currentTrajectory.bounce.length || currentTrajectory.power.length !== currentTrajectory.jump.length) {
                                    message.error("Lineup " + trajectoryID + "'s additional info lists do not have the same length");
                                    return false;
                                }

                                // only check crouch if value is filled in. If no value, accept due to backwards compatibility
                                if (!(currentTrajectory.crouch == null)) {
                                    if (!checkArrayInfo(currentTrajectory.crouch, "boolean")) {
                                        message.error("Lineup " + trajectoryID + "'s crouch list is invalid");
                                        return false;
                                    }
                                    if (currentTrajectory.power.length !== currentTrajectory.crouch.length) {
                                        message.error("Lineup " + trajectoryID + "'s crouch list is invalid");
                                        return false;
                                    }
                                }
                            } else {
                                // check valid jump info
                                if (!checkBooleanInfo(currentTrajectory.jump)) {
                                    message.error("Lineup " + trajectoryID + "'s jump value is invalid");
                                    return false;
                                }

                                // only check crouch if value is filled in. If no value, accept due to backwards compatibility
                                if (!(currentTrajectory.crouch == null)) {
                                    if (!checkBooleanInfo(currentTrajectory.crouch)) {
                                        message.error("Lineup " + trajectoryID + "'s crouch value is invalid");
                                        return false;
                                    }
                                }
                            }


                            for (let n = 0; n < currentTrajectory.path.length; n++) {
                                let coord = currentTrajectory.path[n];
                                // check valid path
                                if (!checkArrayInfo(coord, "number") || coord.length !== 2) {
                                    message.error("Lineup " + trajectoryID + " does not contain a path or contains invalid coordinate(s)");
                                    return false;
                                } else if (coord[0] < 0 || coord[0] > map_width || coord[1] < 0 || coord[1] > map_height) {
                                    message.error("Lineup " + trajectoryID + " travels outside the bounds of the " + mapName + " map");
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }

    // updates uniqueNew since it does not overwrite the reference. checks through each level of uniqueNew to make sure we are not accesing properties of a key that does not exist
    updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path) {

        if (!(mapName in uniqueNew)) {
            // if map does not exist in uniqueNew
            uniqueNew[mapName] = {
                [agentName]: {
                    [useCase]: {
                        [endPoint]: {
                            coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                            trajectoryList: {
                                [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                            }
                        }
                    }
                }
            }
        } else if (!(agentName in uniqueNew[mapName])) {
            // if agent does not exist in uniqueNew
            uniqueNew[mapName][agentName] = {
                [useCase]: {
                    [endPoint]: {
                        coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                        trajectoryList: {
                            [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                        }
                    }
                }
            }
        } else if (!(useCase in uniqueNew[mapName][agentName])) {
            // if useCase does not exist in uniqueNew
            uniqueNew[mapName][agentName][useCase] = {
                [endPoint]: {
                    coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                    trajectoryList: {
                        [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                    }
                }
            }
        } else if (!(endPoint in uniqueNew[mapName][agentName][useCase])) {
            // if endPoint does not exist in uniqueNew
            uniqueNew[mapName][agentName][useCase][endPoint] = {
                coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                trajectoryList: {
                    [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                }
            }
        } else {
            uniqueNew[mapName][agentName][useCase][endPoint].trajectoryList[path] = otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path];
        }
    }


    // merges otherTrajectories into origTrajectories. updates object since it does not overwrite the reference. returns uniqueNew which contains the trajectories that have been added/updated in orig
    mergeTrajectories(origTrajectories, otherTrajectories) {

        let uniqueNew = {};

        Object.keys(otherTrajectories).forEach(mapName => {
            Object.keys(otherTrajectories[mapName]).forEach(agentName => {
                Object.keys(otherTrajectories[mapName][agentName]).forEach(useCase => {
                    Object.keys(otherTrajectories[mapName][agentName][useCase]).forEach(endPoint => {
                        Object.keys(otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList).forEach(path => {

                            // check through each level of origTrajectories to make sure we are not accessing properties of a key that does not exist
                            if (!(mapName in origTrajectories)) {

                                // if map does not exist in origTrajectories
                                origTrajectories[mapName] = {
                                    [agentName]: {
                                        [useCase]: {
                                            [endPoint]: {
                                                coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                                                trajectoryList: {
                                                    [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                                                }
                                            }
                                        }
                                    }
                                }
                                this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);

                            } else if (!(agentName in origTrajectories[mapName])) {

                                // if agent does not exist in origTrajectories
                                origTrajectories[mapName][agentName] = {
                                    [useCase]: {
                                        [endPoint]: {
                                            coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                                            trajectoryList: {
                                                [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                                            }
                                        }
                                    }
                                }
                                this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);

                            } else if (!(useCase in origTrajectories[mapName][agentName])) {

                                // if useCase does not exist in origTrajectories
                                origTrajectories[mapName][agentName][useCase] = {
                                    [endPoint]: {
                                        coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                                        trajectoryList: {
                                            [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                                        }
                                    }
                                }
                                this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);

                            } else if (!(endPoint in origTrajectories[mapName][agentName][useCase])) {
                                // if endPoint does not exist in origTrajectories
                                origTrajectories[mapName][agentName][useCase][endPoint] = {
                                    coord: otherTrajectories[mapName][agentName][useCase][endPoint].coord,
                                    trajectoryList: {
                                        [path]: otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]
                                    }
                                }
                                this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);

                            } else if (!(path in origTrajectories[mapName][agentName][useCase][endPoint].trajectoryList)) {

                                // if path does not exist in origTrajectories
                                origTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path] = otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path];
                                this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);

                            } else {

                                // only update path object if orig and other differs
                                if (JSON.stringify(origTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path]) !== JSON.stringify(otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path])) {
                                    origTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path] = otherTrajectories[mapName][agentName][useCase][endPoint].trajectoryList[path];
                                    this.updateUniqueNew(uniqueNew, otherTrajectories, mapName, agentName, useCase, endPoint, path);
                                }

                            }
                        })
                    })
                })
            })
        })

        return uniqueNew;

    }

    // updates trajectoryData and endPointMap. redraw with new endpoint map
    setCurrentTrajectories(updatedTrajectories, customTrajectories) {
        let newEndpointMap = {};

        if (this.state.map != null && this.state.agent != null && this.state.useCase != null) {
            if (this.state.map.name in updatedTrajectories) {
                if (this.state.agent in updatedTrajectories[this.state.map.name]) {
                    if (this.state.useCase in updatedTrajectories[this.state.map.name][this.state.agent]) {
                        newEndpointMap = updatedTrajectories[this.state.map.name][this.state.agent][this.state.useCase]
                    }
                }
            }

            // doesn't call this.drawTrajectories because it uses a new endpoint map that has not been set as the current endPointMap
            drawTrajectories(newEndpointMap, this.openLineup, this.setStatus, this.state.useCase);
        }

        try {
            localStorage.setItem('customTrajectories', JSON.stringify(customTrajectories));
        } catch (e) {
            message.info('Could not save custom lineups to local storage. If this is unintended, make sure cookies are enabled for this website.', 5)
        }

        this.setState({ trajectoryData: updatedTrajectories, endPointMap: newEndpointMap, customTrajectories: customTrajectories })
    }

    clickAbout() {
        this.setState({
            // user selection and corresponding button maps
            map: null,
            mapMap: null,
            agent: null,
            agentMap: null,
            useCase: null,
            useCaseMap: null,

            endPointMap: null,
            currentEndpointID: null,
            currentTrajectoryID: null,
            newTrajectory: [],
            removeTrajectoryInfo: null,

            // modal and button group statuses
            lineup: false,
            buttonStatus: 'default',
            addModal: false,
            deleteModal: false,
            about: true,
            howto: false,

            status: 'map'
        })

        removeMap();
        this.initializeMapMap();

    }

    clickHowTo() {
        this.setState({
            // user selection and corresponding button maps
            map: null,
            mapMap: null,
            agent: null,
            agentMap: null,
            useCase: null,
            useCaseMap: null,

            endPointMap: null,
            currentEndpointID: null,
            currentTrajectoryID: null,
            newTrajectory: [],
            removeTrajectoryInfo: null,

            // modal and button group statuses
            lineup: false,
            buttonStatus: 'default',
            addModal: false,
            deleteModal: false,
            about: false,
            howto: true,

            status: 'map'
        })

        removeMap();
        this.initializeMapMap();

    }

    render() {
        return (
            <Layout>
                <Sider className='menus' style={{ backgroundColor: 'white' }} width={160}
                    collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} collapsedWidth='0'
                >
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Row>
                            <SelectionMenu
                                agentMap={this.state.agentMap}
                                mapMap={this.state.mapMap}
                                useCaseMap={this.state.useCaseMap}
                                status={this.state.status}
                                setAgent={this.setAgent}
                                setMap={this.setMap}
                                setUseCase={this.setUseCase}
                                mapObj={this.state.map}
                                agentName={this.state.agent}
                            />
                        </Row>
                        <Row style={{ flex: '1' }}>
                            <AbilityMenu
                                useCaseMap={this.state.useCaseMap}
                                setUseCase={this.setUseCase}
                                status={this.state.status}
                                useCase={this.state.useCase}
                            />
                        </Row>
                        <Row>
                            <AddButtonGroup
                                mapSelected={this.state.map != null}
                                agentSelected={this.state.agent != null}
                                useCaseSelected={this.state.useCase != null}
                                customExist={this.state.customTrajectories != null && Object.keys(this.state.customTrajectories).length !== 0}
                                buttonStatus={this.state.buttonStatus}
                                onAdd={this.onAdd}
                                onAddConfirm={this.onAddConfirm}
                                onCancel={this.onCancel}
                                onRemove={this.onRemove}
                                openExport={this.openExport}
                                openImport={this.openImport}
                                customTrajectories={this.state.customTrajectories}
                                uploadTrajectoryJSON={this.uploadTrajectoryJSON}
                            />
                        </Row>
                    </div>
                </Sider>
                <Layout>
                    <Content style={{ overflow: 'auto', backgroundColor: 'rgb(240,236,228)' }}>
                        <ValorantMap visible={this.state.status !== 'map'} map={this.state.map} />
                        <Drawer visible={this.state.lineup} title={this.state.currentEndpointID != null ? this.state.endPointMap[this.state.currentEndpointID].trajectoryList[this.state.currentTrajectoryID].name : null} onClose={this.closeLineup} closable={false} placement='left' width='450'>
                            <Lineup agent={this.state.agent} endPointID={this.state.currentEndpointID} trajectoryID={this.state.currentTrajectoryID} map={this.state.map} useCase={this.state.useCase} endPointMap={this.state.endPointMap} exportJSON={this.exportJSON}> </Lineup>
                        </Drawer>
                        <AddTrajectoryModal addModal={this.state.addModal} onModalClose={this.onModalClose} onSuccessClose={this.onSuccessClose} addCustomTrajectory={this.addCustomTrajectory} newTrajectory={this.state.newTrajectory} map={this.state.map} agent={this.state.agent} useCase={this.state.useCase} />
                        <DeleteTrajectoryModal deleteModal={this.state.deleteModal} onModalClose={this.onModalClose} onSuccessClose={this.onSuccessClose} deleteCustomTrajectory={this.deleteCustomTrajectory} map={this.state.map} agent={this.state.agent} useCase={this.state.useCase} trajectoryInfo={this.state.removeTrajectoryInfo} customTrajectories={this.state.customTrajectories} exportJSON={this.exportJSON} />
                        <ExportModal exportModal={this.state.exportModal} exportObj={this.state.exportObj} onExportClose={this.onExportClose} exportJSON={this.exportJSON} />
                        <ImportModal importModal={this.state.importModal} uploadTrajectoryJSON={this.uploadTrajectoryJSON} onImportClose={this.onImportClose} addCustomTrajectory={this.addCustomTrajectory} />
                        {/* <UpdateModal updateModal={this.state.updateModal} onUpdateClose={this.onUpdateClose}/> */}
                    </Content>
                </Layout>
            </Layout>
        )
    }

}

export default MapPage;