import React from 'react';
import { Form, Input, InputNumber, Button, message, Modal, Space, Switch, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createTrajectory} from '../utils/TrajectoryUtils';
import 'antd/dist/antd.css';

const { TextArea } = Input;

function labeledSwitchForm(label, fieldName, initialValue=false, field={}, labelStyle={marginBottom: 0}, valuePropName="checked") {
    return (
        <Form.Item
            label={label}
            style={labelStyle}
        >
            <Form.Item
                {...field}
                name={fieldName}
                initialValue={initialValue}
                valuePropName={valuePropName}
                style={{ marginBottom: 0 }}
            >
                <Switch />
            </Form.Item>                 
        </Form.Item>
    );
}

let AddTrajectoryModal = (props) => {

    let path = props.newTrajectory.slice();
    path.reverse();

    const [form] = Form.useForm();

    const onFinish = values => {
        let customTrajectory = {};
        let mapName = props.map.name,
            agent = props.agent,
            useCase = props.useCase,
            coord = path[path.length - 1];
        let endPointID = 'endPointx' + coord[0] + 'y' + coord[1];
        let pathID = 'path';
        path.forEach(point => {
            pathID += 'x' + point[0] + 'y' + point[1]
        });
        let pathObject = {};

        let images = [];
        values.images.forEach(image => {
            images.push({
                "text": image.description,
                "src": image.image,
                "video": image.video
            })
        })

        if (agent === "Sova") {
            let power = [];
            let bounce = [];
            let jump = [];
            let crouch = [];
            values.additional_info.forEach(info => {
                power.push(info.power);
                bounce.push(info.bounce);
                jump.push(info.jump);
                crouch.push(info.crouch);
            });

            pathObject = {
                "name": values.name,
                "power": power,
                "bounce": bounce,
                "jump": jump,
                "crouch": crouch,
                "path": path,
                "images": images 
            }

            
        } else {
            pathObject = {
                "name": values.name,
                "jump": values.jump,
                "crouch": values.crouch,
                "path": path,
                "images": images
            }
        }

        customTrajectory = createTrajectory(mapName, agent, useCase, endPointID, coord, pathID, pathObject);

        props.addCustomTrajectory(customTrajectory);
        props.onSuccessClose();
        form.resetFields();
    };

    const onCancel = () => {
        props.onModalClose();
        form.resetFields();
    }

    let title = "New trajectory for " + props.agent + ": " + props.useCase;

    let additionalInfo;
    if (props.agent === "Sova") {
        additionalInfo = <Form.List
                name="additional_info"
                rules={[
                    {
                        validator: async (_, additional_info) => {
                            if (!additional_info || additional_info.length < 1) {
                                message.info('Need at least power, bounce, and jump info for one arrow')
                                return Promise.reject(new Error('Need at least power, bounce, and jump info for one arrow'));
                            }
                        },
                    }
                ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 0, overflow: 'auto' }} align="baseline">
                                <Form.Item
                                    label="Power"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'power']}
                                        rules={[{ required: true, message: 'Missing power' }]}
                                    >
                                        <InputNumber min={0} max={3} style={{width: 50}}/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label="Bounce"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'bounce']}
                                        rules={[{ required: true, message: 'Missing bounce' }]}
                                    >
                                        <InputNumber min={0} max={2} style={{width: 50}}/>
                                    </Form.Item>
                                </Form.Item>
                                {labeledSwitchForm("Jump", [field.name, "jump"], false, field)}
                                {labeledSwitchForm("Crouch", [field.name, "crouch"], false, field)}
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Space>
                        ))}
                        {fields.length < 2 ? 
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add arrow info
                            </Button>
                        </Form.Item>
                        : null}
                    </>
                )}
            </Form.List>
    } else {
        additionalInfo = (
            <Form.Item>
                <Space style={{ display: 'flex', marginBottom: 0, overflow: 'auto' }} align="baseline">
                    {labeledSwitchForm("Jump", "jump", false)}
                    {labeledSwitchForm("Crouch", "crouch", false)}
                </Space>
            </Form.Item>
        );
    }

    return (
        <Modal title={title} visible={props.addModal} footer={null} onCancel={onCancel}>
            <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                {/* name of agent and ability */}
                <Form.Item label="Name" style={{ display: 'flex', marginBottom: 0 }}>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Missing name'}]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>

                {/* additional info */}
                {additionalInfo}

                {/* image and description list */}
                <Form.List
                    name="images"
                    rules={[
                        {
                            validator: async (_, images) => {
                                if (!images || images.length < 1) {
                                    message.info('Need at least one image/video')
                                    return Promise.reject(new Error('Need at least one image/video'));
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <div style={{ marginBottom: 20}}>
                                    <Row gutter='16' style={{ marginBottom: 10 }}>
                                        <Col flex='1'>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'image']}
                                                rules={[{ required: true, message: 'Missing URL' }]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input placeholder="URL"/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            {labeledSwitchForm("Video", [field.name, "video"], false, field)}
                                        </Col>
                                        <Col style={{ marginRight: 0, }}>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginBottom: 0 }}/>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 0 }}>
                                        <Col flex='1'>
                                            <Form.Item style={{ marginBottom: 0 }} label="">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'description']}
                                                    style={{ marginBottom: 0 }}
                                                    rules={[{ required: true, message: 'Missing description' }]}
                                                >
                                                    <TextArea placeholder="Description"/>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            {fields.length < 10 ?
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add image/video
                                    </Button>
                                </Form.Item>
                            : null}
                        </>
                    )}
                </Form.List>

                {/* submit button */}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddTrajectoryModal;