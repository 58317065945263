import React from 'react';
import 'antd/dist/antd.css';
import {
    checkBooleanInfo
  } from '../utils/TrajectoryUtils';

class SovaLineup extends React.Component {

    getCrouchInfo() {
        if (!checkBooleanInfo(this.props.crouch)) {
            return "";
        }

        return this.props.crouch ? "Crouch: Yes" : "Crouch: No";
    }

    render() {
        return (
            <div className='sovalineup_div'>
                <h3>Power: {this.props.power} Bounces: {this.props.bounce} Jump: {this.props.jump ? "Yes" : "No"} {this.getCrouchInfo()}</h3>
            </div>
        )
    }

}

export default SovaLineup;