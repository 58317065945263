import React from 'react';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import 'antd/dist/antd.css';

const rootSubmenuKeys = ['usecases'];

class AbilityMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openKeys: ['usecases']
        };
    }

    // enables closing submenu after setting openKeys
    onOpenChange = keys => {
        const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys: keys })
        } else {
            this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
        }
    };

    render() {

        return (
            <Menu
                mode='inline'
                style={{ marginLeft: 0, width: '100%' }}
                selectedKeys=''
                inlineIndent='16'
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
            >
                {this.props.useCaseMap != null ?
                    <SubMenu key='usecases' title={this.props.useCase != null ? this.props.useCase : 'Abilities'} >
                        {Object.keys(this.props.useCaseMap).map(useCaseName => (
                            <Menu.Item key={useCaseName} disabled={this.props.useCaseMap[useCaseName]} onClick={() => this.props.setUseCase(useCaseName)}>{useCaseName}</Menu.Item>
                        ))}
                    </SubMenu> 
                    :
                    null
                }
            </Menu>
        )
    }

}

export default AbilityMenu;