import { message } from "antd";

function createTrajectory(mapName, agent, useCase, endPointID, coord, pathID, pathObj) {
    let trajectory = {
        [mapName]: {
            [agent]: {
                [useCase]: {
                    [endPointID]: {
                        "coord": coord,
                        "trajectoryList": {
                            [pathID]: pathObj
                        }
                    }
                }
            }
        }
    }

    return trajectory;
}

/*
Typical image object. Check if text and source are both strings.
Check if video is a boolean.
{
    "text": "google1 description",
    "src": "https://google1.com",
    "video": false
}
*/
function checkTrajectoryImage(imageObj, imageTrajectoryId) {
    if (imageObj == null) {
        message.error("Trajectory " + imageTrajectoryId + " is missing image information.");
        return false;
    }

    // check whether fields are null
    if (imageObj.text == null || 
        imageObj.src == null) {
        message.error("Trajectory " + imageTrajectoryId + "'s images are missing description or image URL.");
        return false;
    }
        
    if (typeof imageObj.text !== "string" || 
        typeof imageObj.src !== "string") {
        message.error("Trajectory " + imageTrajectoryId + "'s images' description or image URL are not strings.");
        return false;
    }

    // Only check the video attribute if it exists, for backwards compatibility
    if (!(imageObj.video == null)) {
        if (!checkBooleanInfo(imageObj.video)) {
            message.error("Trajectory " + imageTrajectoryId + "'s images' video value is invalid.");
            return false;
        }
    }

    return true;
}

// Checks that the array is an array type, and is not null.
// Checks that the array is all one type, and by default checks that there is at least one value in it.
function checkArrayInfo(arr, arrType, minArrSize = 1) {
    if (arr == null) {
        return false;
    }

    if (!Array.isArray(arr)) {
        return false;
    }

    if (arr.length < minArrSize) {
        return false;
    }

    // every element of array need to be the arrType
    if (arr.some((value) => typeof(value) !== arrType)) {
        return false;
    }

    return true;
}

function checkBooleanInfo(boolValue) {
    return boolValue !== null && typeof boolValue == "boolean";
}

export {
    createTrajectory,
    checkTrajectoryImage,
    checkArrayInfo,
    checkBooleanInfo,
};