import React, { Component } from 'react';
import { Button, Divider, message, Modal, Typography } from 'antd';
import 'antd/dist/antd.css';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import ClipboardJS from 'clipboard';

const { Title, Paragraph, Link } = Typography;

let copy;

class ExportModal extends Component {

    componentDidMount() {
        copy = new ClipboardJS('.copyAllBtn');
        copy.on('success', function(e) {
            message.success('Successfully copied trajectory data to clipboard');
        })

        copy.on('error', function (e) {
            message.error('Could not copy trajectory data to clipboard')
        })
    }

    componentWillUnmount() {
        copy.destroy();
    }

    render() {
        return(
            <Modal title='Export to...' visible={this.props.exportModal} footer={null} onCancel={this.props.onExportClose}>
                <Typography>
                    <Title level={2}>.json file</Title>
                    <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Save your lineups as a file to your device so they don't get lost</Paragraph>
                </Typography>
                <Button icon={<DownloadOutlined />} onClick={() => this.props.exportJSON(this.props.exportObj)}>Download</Button>

                <Divider/>
                <Typography>
                    <Title level={2}>clipboard</Title>
                    <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Copy your lineups to your clipboard to easily paste to websites like <Link href='https://pastebin.com/' target='_blank'>Pastebin</Link></Paragraph>
                </Typography>
                <Button className='copyAllBtn' data-clipboard-text={JSON.stringify(this.props.exportObj)} icon={<CopyOutlined />}>Copy</Button>

                {/* <Row justify="space-around">
                    <Col span={10}>
                        <Title level={2}>.json file</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Save your lineups as a file to your computer so they don't get lost</Paragraph>
                    </Col>
                    <Col span={10}>
                        <Title level={2}>clipboard</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Copy your lineups to your clipboard to easily paste to websites like <Link href='https://pastebin.com/' target='_blank'>Pastebin</Link></Paragraph>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={10}>
                        <Button icon={<DownloadOutlined />} onClick={() => this.props.exportJSON(this.props.exportObj)}>Download</Button>
                    </Col>
                    <Col span={10}>
                        <Button className='copyAllBtn' data-clipboard-text={JSON.stringify(this.props.exportObj)} icon={<CopyOutlined/>}>Copy</Button>
                    </Col>
                </Row> */}
            </Modal>
        )
    }
}

export default ExportModal;