import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';
import 'antd/dist/antd.css';

const {Paragraph} = Typography;

class FirstTimeModal extends Component {

    render() {
        return(
            <Modal title='Welcome to Handbook.gg' visible={this.props.firstTimeModal} footer={null} onCancel={this.props.onFirstTimeClose}>
                <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>If this is your first time visiting, consider checking the How To section to learn how to start building your personal lineup handbook and share your lineups with others!</Paragraph>
                <Link to="howto" onClick={() => this.props.onFirstTimeClose()}>
                    <Button>How To</Button>
                </Link>
                
            </Modal>
        )
    }
}

export default FirstTimeModal;