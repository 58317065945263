import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom"; 

import App from './App.js';
import AboutPage from './pages/AboutPage.js';
import HowToPage from './pages/HowToPage.js';
import MapPage from './pages/MapPage.js';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<MapPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="howto" element={<HowToPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

