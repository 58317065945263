import React from 'react';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import 'antd/dist/antd.css';

const rootSubmenuKeys = ['maps', 'agents', 'usecases'];

class SelectionMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openKeys: ['maps']
        };

        this.mapClick = this.mapClick.bind(this);
        this.agentClick = this.agentClick.bind(this);

    }


    onOpenChange = keys => {
        const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({openKeys: keys})
        } else {
            this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : []})
        }
    };

    mapClick(mapName) {
        if (this.props.status === "map") {
            this.setState({openKeys: ['agents']});
        } else if (this.props.status === "agent") {
            this.setState({openKeys: ['agents']});
        } else if (this.props.status === "useCase") {
            this.setState({openKeys: ['usecases']});
        }
        this.props.setMap(mapName);
        
    }
    
    agentClick(agentName) {
        this.props.setAgent(agentName);
        this.setState({openKeys: ['usecases']});
    }

    render() {

        return(
            <Menu
                mode='vertical'
                style={{ width: '100%'}}
                selectedKeys=''
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
            >

                {/* map selector */}
                {this.props.mapMap != null ? <SubMenu key='maps' title={this.props.mapObj != null ? this.props.mapObj.name : 'Maps'}>
                    {Object.keys(this.props.mapMap).map(mapName => (
                        <Menu.Item key={mapName} disabled={this.props.mapMap[mapName]} onClick={() => this.mapClick(mapName)}>{mapName}</Menu.Item>
                    ))}
                </SubMenu> : null}

                {/* agent selector */}
                {this.props.agentMap != null ? <SubMenu key='agents' title={this.props.agentName != null ? this.props.agentName : 'Agents'}>
                    {Object.keys(this.props.agentMap).map(agentName => (
                        <Menu.Item key={agentName} disabled={this.props.agentMap[agentName]} onClick={() => this.agentClick(agentName)}>{agentName}</Menu.Item>
                    ))}
                </SubMenu> : null}

                {/* ability selector */}
                {/* {this.props.useCaseMap != null ? <SubMenu key='usecases' title='Abilities'>
                    {Object.keys(this.props.useCaseMap).map(useCaseName => (
                        <Menu.Item key={useCaseName} disabled={this.props.useCaseMap[useCaseName]} onClick={() => this.props.setUseCase(useCaseName)}>{useCaseName}</Menu.Item>
                    ))}
                </SubMenu> : null} */}

            </Menu>
        )
    }

}

export default SelectionMenu;