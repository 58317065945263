import React from 'react';
import { Button, Layout, Col, notification, Row } from 'antd';
import { Link, Outlet } from 'react-router-dom';

import { MessageOutlined } from '@ant-design/icons';
import FirstTimeModal from './components/FirstTimeModal';

const { Header } = Layout;

/**
 * Contains header for navigation and router outlet that displays pages (Map, About, HowTo)
 */
class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstTimeModal: false,
    }

    this.checkAndShowFirstTimeModal = this.checkAndShowFirstTimeModal.bind(this);
    this.onFirstTimeClose = this.onFirstTimeClose.bind(this);
  }

  componentDidMount() {
    this.checkAndCreateCookieAck();
    this.checkAndCreateDiscordAnnouncement();
    this.checkAndShowFirstTimeModal();
    
  }

  checkAndCreateCookieAck() {
    let acknowledgement;
    try {
      acknowledgement = JSON.parse(localStorage.getItem('notificationAcknowledge'));
    } catch (e) {
    }

    if (!acknowledgement) {
      notification['info']({
        message: 'Cookie Policy',
        duration: 0,
        description:
          'This website uses cookies to cache lineups for subsequent website visits. By continuing to use Handbook, you agree to our use of cookies as described in the Privacy Policy.',
        placement: 'bottomRight',
      })
      try {
        localStorage.setItem('notificationAcknowledge', true);
      } catch (e) {
      }
    }
  }

  checkAndCreateDiscordAnnouncement() {
    let discord;
    try {
      discord = JSON.parse(localStorage.getItem('discordAcknowledge'));
    } catch (e) {
    }

    if (!discord) {
      notification['info']({
        message: <p style={{ color: 'white' }}>Handbook Discord Server</p>,
        duration: 0,
        description: <p style={{ color: 'white' }}>Check out the <a href='https://discord.gg/KeXRZJdEHd' target='_blank' style={{ color: 'white' }}><u>Handbook Discord Server</u></a>!</p>,
        icon: <MessageOutlined style={{ color: 'white' }} />,
        style: { backgroundColor: '#7289DA' },
        placement: 'bottomRight',
      })
      try {
        localStorage.setItem('discordAcknowledge', true);
      } catch (e) {
      }
    }
  }

  checkAndShowFirstTimeModal() {
    let firstTime = true;
    try {
      firstTime = JSON.parse(localStorage.getItem('firstTime'));
      if (firstTime == null) {
        firstTime = true;
      }
    } catch (e) {
      firstTime = true;
    }

    if (firstTime) {
      this.setState({ firstTimeModal: true });
      try {
        localStorage.setItem('firstTime', false);
        // localStorage.setItem('version', VERSION);
      } catch (e) {

      }
    }
  }

  onFirstTimeClose() {
    this.setState({ firstTimeModal: false });
  }

  render() {
    return (
      <Layout style={{ height: '100vh'}}>
        <Header className='header' style={{ backgroundColor: 'rgba(17,17,17,255)'}}>
          <Row wrap={false} style={{height: '64px'}}>
            <Col style={{marginLeft: '25px'}}>
                <h1 style={{ color: 'white', fontFamily: 'Valorant Font', fontSize: '25px' }}>HAndbook</h1>
            </Col>
            <Col flex='1'/>
            <Col style={{marginRight: '5px'}}>
              <Link to="/">
                <Button type='text' style={{ backgroundColor: 'rgba(17,17,17,255)' }}>
                  <h1 style={{ color: 'white', fontFamily: 'Valorant Font', fontSize: '15px' }}>Map</h1>
                </Button>
              </Link> 
              <Link to="about">
                <Button type='text' style={{ backgroundColor: 'rgba(17,17,17,255)' }}>
                  <h1 style={{ color: 'white', fontFamily: 'Valorant Font', fontSize: '15px' }}>About</h1>
                </Button>
              </Link> 
              <Link to="howto">
                <Button type='text' style={{ backgroundColor: 'rgba(17,17,17,255)' }}>
                  <h1 style={{ color: 'white', fontFamily: 'Valorant Font', fontSize: '15px' }}>How To</h1>
                </Button>
              </Link>
            </Col>
          </Row>
        </Header>
        <Outlet />
        <FirstTimeModal firstTimeModal={this.state.firstTimeModal} onFirstTimeClose={this.onFirstTimeClose}/>
      </Layout>
    );
  }
}

export default App;
