import React, { Component } from 'react';
import { Layout, Typography, Divider, Space } from 'antd';

const { Title, Paragraph, Link } = Typography;
const { Content } = Layout;


class AboutPage extends Component {

    render() {
        return (
            <Content style={{ overflow: 'auto', backgroundColor: 'rgb(240,236,228)' }}>
                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                    <Typography style={{ maxWidth: '1000px', marginBottom: '150px', marginTop: '50px' }}>

                        <Title level={2}>HANDBOOK v1.10.0</Title>

                        <Title level={4}>About</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Handbook is a tool for saving, organizing, and sharing agent ability lineups for Riot Game's tactical first-person shooter game Valorant.
                            <br />
                            Handbook enables users to:
                            <ul>
                                <li>create and save ability lineups</li>
                                <li>organize and visualize lineups on Valorant maps</li>
                                <li>share lineups with the community</li>
                            </ul>

                            Come check out the <Link href='https://discord.gg/KeXRZJdEHd' target='_blank'>Handbook Discord Server</Link>! We have dedicated channels for Valorant related topics like sharing lineups and looking for groups to play with!
                        </Paragraph>

                        <Divider />
                        <Title level={4}>Maps and Agents Supported</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Handbook currently supports all Valorant maps and agents available as of Episode 7 Act I (Patch 7.0):
                            <br />Maps: Bind, Haven, Split, Ascent, Icebox, Breeze, Fracture, Pearl, Lotus
                            <br />Agents: Brimstone, Phoenix, Sage, Sova, Viper, Cypher, Reyna, Killjoy, Breach, Omen, Jett, Raze, Skye, Yoru, Astra, KAY/O, Chamber, Neon, Fade, Harbor, Gekko, Deadlock
                        </Paragraph>

                        <Divider />
                        <Title level={4}>Privacy Policy</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Handbook does not collect any personal information, but it does use cookies to save your custom lineups in local storage (which do not expire) for subsequent website visits. You do not need cookies enabled to be able to use this website, just make sure to export your lineups before you leave.
                        </Paragraph>

                        <Divider />
                        <Title level={4}>Contact</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            If you have any questions or concerns, please reach out to us via the <Link href='https://discord.gg/KeXRZJdEHd' target='_blank'>Handbook Discord Server</Link> or email us at handbookgg@gmail.com!
                        </Paragraph>

                        <Divider />
                        <Title level={4}>Disclaimer</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Handbook was created under Riot Games' "Legal Jibber Jabber" policy using assets owned by Riot Games.  Riot Games does not endorse or sponsor this project.
                        </Paragraph>

                        <Divider />
                        <Title level={4}>Change Log</Title>
                        <Title level={5}>07/02/2023 v1.10.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Updated Brind to reflect map changes
                                </li>
                                <li>
                                    Added Deadlock
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>04/21/2023 v1.9.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Gekko
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>01/30/2023 v1.8.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Lotus as supported map
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>11/07/2022 v1.7.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Harbor
                                </li>
                                <li>
                                    Updated Fracture and Pearl to reflect map changes
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>09/16/2022 v1.6.1</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Made minor change to website description
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>06/22/2022 v1.6.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Pearl as supported map
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>06/05/2022 v1.5.1</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Fade
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>03/06/2022 v1.5.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Updated Icebox to reflect 4.04 map changes
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>01/23/2022 v1.4.1</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Chamber and Neon
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>09/10/2021 v1.4.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Fracture as supported map
                                </li>
                                <li>
                                    Minor bug fixes
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>06/26/2021 v1.3.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added crouch to lineup info
                                </li>
                                <li>
                                    Added KAY/O
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>05/07/2021 v1.2.1</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Handbook Discord Server information
                                </li>
                                <li>
                                    Minor bug fixes
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>04/28/2021 v1.2.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added Breeze as supported map
                                </li>
                                <li>
                                    Updated Cypher Trapwire visualization
                                </li>
                                <li>
                                    Clicking on endpoints with one lineup will immediately bring up lineup details
                                </li>
                                <li>
                                    Clicking on endpoints with one lineup while removing will immediately bring up removal confirmation
                                </li>
                            </ul>
                        </Paragraph>
                        <Title level={5}>03/21/2021 v1.1.0</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            <ul>
                                <li>
                                    Added video support for lineup details (includes YouTube, Twitch [videos only], Streamable, and video addresses [ending in file name extensions like .mp4])
                                </li>
                                <li>
                                    Updated ability menu to drop down for easier in-game use
                                </li>
                                <li>
                                    Updated menus to display selected item
                                </li>
                            </ul>
                        </Paragraph>

                    </Typography>
                </Space>
            </Content>
            
        );
    }

}

export default AboutPage;