import React, { Component } from 'react';
import { Button, Divider, Input, message, Modal, Space, Typography, Upload } from 'antd';
import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title, Paragraph, Link } = Typography;
const {TextArea} = Input;

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

class ImportModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paste_key: '',
            paste_text: '',
        }

        this.onPasteKeyChange = this.onPasteKeyChange.bind(this);
        this.onPasteTextChange = this.onPasteTextChange.bind(this);

        this.getPaste = this.getPaste.bind(this);
        this.getText = this.getText.bind(this);
    }

    onPasteKeyChange(e) {
        this.setState({paste_key: e.target.value});
    }

    onPasteTextChange(e) {
        this.setState({paste_text: e.target.value})
    }

    getPaste() {
        let paste_key = this.state.paste_key;
        let addCustomTrajectory = this.props.addCustomTrajectory;
        let onImportClose = this.props.onImportClose;

        if (paste_key.match("^[A-Za-z0-9]+$")) {
            let data = {
                paste_key: paste_key
            }
            axios.post('https://pastebinproxy.handbook.gg/getPaste', data)
                .then(function (response) {
                    if (response.data !== null) {
                        if (response.data.name && response.data.name === 'Error') {
                            message.error('Failed to import from https://pastebin.com/' + paste_key);
                        } else {
                            try {
                                let trajectories = response.data;
                                let success = addCustomTrajectory(trajectories);
                                if (success) {
                                    message.success('Successfully imported from https://pastebin.com/' + paste_key);
                                    onImportClose();
                                } else {
                                    message.error('The file imported from https://pastebin.com/' + paste_key + ' resulted in errors. Please check if the lineups are valid.');
                                }

                            } catch (e) {
                                console.log(e);
                                message.error('The file imported from https://pastebin.com/' + paste_key + ' was not readable as a JSON. Please check if the file is valid.');
                            }
                        }
                    } else {
                        message.error('https://pastebin.com/' + paste_key + ' returned null.');
                    }

                }).catch(function (response) {
                    message.error('The Pastebin proxy has returned an error. Please contact handbookgg@gmail.com')
                    console.log(response);
                });
        } else {
            message.error('The paste key should only contain letters and numbers.')
        }
                
    }

    getText() {
        let paste_text = this.state.paste_text;
        let addCustomTrajectory = this.props.addCustomTrajectory;
        let onImportClose = this.props.onImportClose;

        try {
            let trajectories = JSON.parse(paste_text);
            let success = addCustomTrajectory(trajectories);
            if (success) {
                message.success('Successfully imported from clipboard.');
                onImportClose();
            } else {
                message.error('The lineup text resulted in errors. Please check if the lineups are valid.');
            }

        } catch (e) {
            console.log(e);
            message.error('The lineup text could not be read as a JSON. Please check for errors.');
        }
    }

    upload() {
        document.getElementById('upload_file').click();
    }

    render() {
        return (
            <Modal title='Import from...' visible={this.props.importModal} footer={null} onCancel={this.props.onImportClose}>
                <Typography>
                    <Title level={2}>.json file</Title>
                    <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Import your custom lineups by uploading a JSON file from your device.</Paragraph>
                </Typography>
                <Upload
                    accept='.json'
                    showUploadList={false}
                    onChange={this.props.uploadTrajectoryJSON}
                    customRequest={dummyRequest}
                >
                    <Button id='upload_file' icon={<UploadOutlined />}>Upload</Button>
                </Upload>

                <Divider/>
                <Typography>
                    <Title level={2}>Pastebin</Title>
                    <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Import your custom lineups directly from <Link href='https://pastebin.com/' target='_blank'>Pastebin</Link> by entering the paste key.</Paragraph>
                </Typography>
                <Space>
                    <div style={{ width: '250px' }}>
                        <Input addonBefore='https://pastebin.com/' onChange={this.onPasteKeyChange}/>
                    </div>
                    <Button onClick={() => this.getPaste()}>Import</Button>
                </Space>
                

                <Divider />
                <Typography>
                    <Title level={2}>clipboard</Title>
                    <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Import your custom lineups by directly copying and pasting your lineup as text.</Paragraph>
                </Typography>
                <TextArea onChange={this.onPasteTextChange}/>
                <div style={{ marginTop: '5px'}}>
                    <Button onClick={() => this.getText()}>Import</Button>
                </div>
                

                
                {/* <Row justify="space-around">
                    <Col span={8}>
                        <Title level={2}>.json file</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Save your lineups as a file to your computer so they don't get lost</Paragraph>
                    </Col>
                    <Col span={12}>
                        <Title level={2}>Pastebin</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>Copy your lineups to your clipboard to easily paste to websites like <Link href='https://pastebin.com/' target='_blank'>Pastebin</Link></Paragraph>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={8}>
                        <Upload
                            accept='.json'
                            showUploadList={false}
                            onChange={this.props.uploadTrajectoryJSON}
                            customRequest={dummyRequest}
                        >
                            <Button id='upload_file' icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Col>
                    <Col span={12}>
                        <Input addonBefore='https://pastebin.com/'/>
                    </Col>
                </Row> */}
            </Modal>
        )
    }
}

export default ImportModal;