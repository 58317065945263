import React, { Component } from 'react';
import { Layout, Typography, Divider, Space } from 'antd';

const { Title, Paragraph, Link } = Typography;
const { Content } = Layout;

class AboutPage extends Component {

    render() {
        return (
            <Content style={{ overflow: 'auto', backgroundColor: 'rgb(240,236,228)' }}>
                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                    <Typography style={{ maxWidth: '1000px', marginBottom: '150px', marginTop: '50px' }}>

                        <Title level={2}>How To</Title>

                        <Title level={4}>Getting Started</Title>
                        <Title level={5}>Here are some lineups that can be imported immediately:</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            All maps:
                            <ul>
                                <li>
                                    <strong>VVVJiRbq</strong> - <Link href='https://pastebin.com/VVVJiRbq' target='_blank'>Cypher Spycams</Link> based on <Link href='https://www.youtube.com/watch?v=uzDsR2mnB88' target='_blank'>this video by Peak</Link><br />
                                </li>
                            </ul>
                            Bind only:
                            <ul>
                                <li>
                                    <strong>WDBpzY02</strong> - <Link href='https://pastebin.com/WDBpzY02' target='_blank'>Sova (Recon Bolts, Post Plant Shock Bolts)</Link> some of which are based on <Link href='https://www.youtube.com/watch?v=btWDzvM5kkA' target='_blank'>this video by Average Jonas</Link>
                                </li>
                                <li>
                                    <strong>p6z2PYEX</strong> - <Link href='https://pastebin.com/p6z2PYEX' target='_blank'>Brimstone (Post Plant Incendiaries)</Link>
                                </li>
                            </ul>
                            Ascent only:
                            <ul>
                                <li>
                                    <strong>nRH24Er8</strong> - <Link href='https://pastebin.com/nRH24Er8' target='_blank'>Cypher (One Way Cyber Cages)</Link> based on <Link href='https://www.youtube.com/watch?v=2Khn-Z2ozfg' target='_blank'>this video by SpicyCurry</Link>
                                </li>
                            </ul>
                            Split only:
                            <ul>
                                <li>
                                    <strong>muHbY9k3</strong> - <Link href='https://pastebin.com/muHbY9k3' target='_blank'>Viper (Toxic Screens, One Way Poison Clouds, Post Plant Snake Bites)</Link> based on <Link href='https://www.youtube.com/watch?v=A-yggaTH6ZE' target='_blank'>this video by Unidaro</Link>
                                </li>
                            </ul>
                            Individual lineups:
                            <ul>
                                <li>
                                    <strong>hSSFF34a</strong> - <Link href='https://pastebin.com/hSSFF34a' target='_blank'>Viper Post Plant Snake Bite for Breeze B Site</Link> from <Link href='https://youtu.be/4itD_RuBTEw?t=534' target='_blank'>this video by Average Jonas</Link>
                                </li>
                            </ul>
                        </Paragraph>

                        <Title level={5}>Looking for more lineups?</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Come check out the lineup sharing channels in the <Link href='https://discord.gg/KeXRZJdEHd' target='_blank'>Handbook Discord Server</Link>!
                        </Paragraph>


                        <Divider />
                        <Title level={4}>Creating and Saving New Valorant Lineups</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            After selecting the map, agent, and ability, clicking the Add button will allow you to create a new ability lineup. You can then create the endpoint or select an existing endpoint on the map and plot a trajectory with up 2 bounces. Upon clicking the Confirm button, you will be prompted to provide additional lineup information.
                            <br /><span style={{ color: 'rgba(255,70,85,255)' }}> It is highly recommended that users export their lineups after modifying to ensure that changes are saved.</span>
                        </Paragraph>
                        <video width='100%' controls>
                            <source src="https://i.imgur.com/YZsasRn.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Find the video <Link href='https://i.imgur.com/YZsasRn.mp4' target='_blank'>here</Link>
                        </video>

                        <Divider />
                        <Title level={4}>Getting Lineup Info</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            Select the map, agent, and ability that you want to see lineup details for. This will show you the endpoints of the lineup trajectories on the map. You can see the trajectories of each endpoint by hovering over them. After clicking an endpoint, select the trajectory that you want to see lineup information for. Clicking the trajectory or the starting point will will bring up the lineup information page. You can click on any of the images to zoom in and get a better look.
                        </Paragraph>
                        <video width='100%' controls>
                            <source src="https://i.imgur.com/0jR1YV9.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Find the video <Link href='https://i.imgur.com/0jR1YV9.mp4' target='_blank'>here</Link>
                        </video>


                        <Divider />
                        {/* <Divider orientation='left'>Importing</Divider> */}
                        <Title level={4}>Importing and Exporting Lineups</Title>

                        <Title level={5}>Importing</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            To import, click the Import button and select the method you'd like to use:
                            <ul>
                                <li>If you choose the import from .json file option, you will be prompted to choose a .json file to upload. </li>
                                <li>If you choose the import from Pastebin option, you will need to type in the paste key (e.g. nRH24Er8 of the URL <Link href='https://pastebin.com/nRH24Er8' target='_blank'>https://pastebin.com/nRH24Er8</Link>).</li>
                                <li>If you choose the import from clipboard option, you will need to copy the lineup text into the text area provided.</li>
                            </ul>
                            Below is an example of importing from Pastebin:
                        </Paragraph>
                        <video width='100%' controls>
                            <source src="https://i.imgur.com/E2Og5bd.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Find the video <Link href='https://i.imgur.com/E2Og5bd.mp4' target='_blank'>here</Link>
                        </video>

                        <Title level={5}>Exporting</Title>
                        <Paragraph style={{ color: 'rgba(118,128,127,255)' }}>
                            To export, click the Export button and select the method you'd like to use:
                            <ul>
                                <li>If you choose the export to .json file option, the file will be downloaded to your device.</li>
                                <li>If you choose the export to clipboard option, the lineup text will be copied to your clipboard and you can then paste it wherever you'd like (although <Link href='https://pastebin.com/' target='_blank'>Pastebin</Link> is currently supported as an import option so it's probably your best option)</li>
                            </ul>
                            Below is an example of exporting to clipboard:
                        </Paragraph>
                        <video width='100%' controls>
                            <source src="https://i.imgur.com/um4xcOI.mp4" type="video/mp4" />
                            Your browser does not support the video tag. Find the video <Link href='https://i.imgur.com/um4xcOI.mp4' target='_blank'>here</Link>
                        </video>

                    </Typography>
                </Space>
            </Content>
        );
    }

}

export default AboutPage;